import Axios from "axios";
import appUrl from "../../app/features/constants/appUrl";
import { axiosApi } from "../../app/features/constants/axios_api";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

const documentsVerification = createSlice({
    name: "documentVerification",
    initialState: {},
    extraReducers: (builder) => {
        builder
            .addCase(getDocumentsVerifications.pending, (state, action) => {
                return { loading: true, updateSuccess: undefined };
            })
            .addCase(getDocumentsVerifications.fulfilled, (state, action) => {
                return { loading: false, list: action.payload.data, count: action.payload.count };
            })
            .addCase(getDocumentsVerifications.rejected, (state, action) => {
                return {
                    loading: false,
                    error: action.error.response && action.error.response.data.msg ? action.error.response.data.msg : action.error.message,
                };
            });

        // builder
        // .addCase(getVerifiedDocuments.pending, (state, action) => {
        //     return { loading: true }
        // })
        // .addCase(getVerifiedDocuments.fulfilled, (state, action) => {

        //     return { loading: false, list: action.payload }
        // })
        // .addCase(getVerifiedDocuments.rejected, (state, action) => {
        //     return {
        //         loading: false,
        //         error: action.error.response && action.error.response.data.msg
        //             ? action.error.response.data.msg
        //             : action.error.message
        //     }
        // });

        builder
            .addCase(getUserDocumentsById.pending, (state, action) => {
                return { loading: true };
            })
            .addCase(getUserDocumentsById.fulfilled, (state, action) => {
                return { loading: false, data: action.payload };
            })
            .addCase(getUserDocumentsById.rejected, (state, action) => {
                return {
                    loading: false,
                    error: action.error.response && action.error.response.data.msg ? action.error.response.data.msg : action.error.message,
                };
            });
    },
});
export default documentsVerification.reducer;

// Thunks
export const getDocumentsVerifications = createAsyncThunk("documentsVerification/fetch", async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosApi.get(appUrl.baseUrl + appUrl.getUnverifiedDrivers + `/?pageNumber=${body.pageNumber}&flag=${body.flag}&perPageRecords=${body.perPageRecords}`);
        return fulfillWithValue({ data: data.data, count: data.count });
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.msg ? error.response.data.msg : error.message);
    }
    // console.log("unverified documents",data)
});
// export const getVerifiedDocuments = createAsyncThunk('verifiedDocuments/fetch', async () => {
//     const { data } = await axiosApi.get(appUrl.baseUrl + appUrl.getVerifiedDrivers);
//     //console.log("verified documents",data)
//     return data.data;
// });

export const getUserDocumentsById = createAsyncThunk("getUsersDocumentsById/fetch", async ({ userId }, { rejectWithValue, fulfillWithValue }) => {
    // console.log("userId",userId)
    try {
        const { data } = await axiosApi.get(appUrl.baseUrl + appUrl.getUserDocumentsById + `?userId=${userId}`);
        return fulfillWithValue(data.data);
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.msg ? error.response.data.msg : error.message);
    }
});
