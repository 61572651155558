import React, { useEffect } from "react";
import appUrl from "../../../constants/appUrl";
import { Image } from "primereact/image";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

import { Button } from "primereact/button";
import { useDispatch, useSelector } from "react-redux";
import { updateDriverDocumentStatus } from "../../../../../redux/slices/drivers_slice";
import { getUserDocumentsById } from "../../../../../redux/slices/documentVerificationSlice";

export default function DocumentsDialog({ user, onHide, userId, updateSuccess, permissions }) {
    const dispatch = useDispatch();
    const getDriverDocumentsStatusReducer = useSelector((state) => state.documentVerifications);
    const { data } = getDriverDocumentsStatusReducer;

    useEffect(() => {
        dispatch(getUserDocumentsById({ userId }));
    }, []);

    const resubmitDocument = (userId, type) => {
        const body = { userId, type, status: "re-submit", comment: "Please re-submit your documents" };
        dispatch(updateDriverDocumentStatus(body));
        onHide();
    };

    useEffect(() => {
        if (updateSuccess) onHide();
    }, [updateSuccess]);

    return (
        <>
            <div className="flex">
                <DataTable responsiveLayout="scroll" value={[data]} className="col-6 p-header-custom p-img-custom">
                    <Column
                        className="maker_colm"
                        field="documents"
                        header="CNIC Images"
                        body={(data) => {
                            return (
                                <div className="flex flex-row justify-content-center">
                                    {data?.documents?.map((imgSrc, index) => {
                                        return (
                                            <>
                                                <div className="" key={index}>
                                                    {imgSrc?.type === "cnic" && imgSrc?.frontImage && <Image height="100px" width="100px" className="ml-2" style={{ objectFit: "contain" }} src={`${appUrl.baseUrl}${imgSrc?.frontImage}`} template="Preview" preview alt="Image Text" />}
                                                    {imgSrc?.type === "cnic" && imgSrc?.backImage && <Image height="100px" width="100px" className="ml-2" style={{ objectFit: "contain" }} src={`${appUrl.baseUrl}${imgSrc?.backImage}`} template="Preview" preview alt="Image Text" />}
                                                </div>
                                            </>
                                        );
                                    })}
                                </div>
                            );
                        }}
                    />
                    <Column
                        className="maker_colm"
                        field="status"
                        header="Status"
                        body={(data) => {
                            return (
                                <div className="flex flex-row justify-content-center">
                                    {data?.documents?.map((data, index) => {
                                        return (
                                            <>
                                                <div className="" key={index}>
                                                    {data.type === "cnic" && <p>{data.status}</p>}
                                                </div>
                                            </>
                                        );
                                    })}
                                </div>
                            );
                        }}
                    />
                    <Column
                        className="maker_colm"
                        field="comment"
                        header="Comment"
                        body={(data) => {
                            return (
                                <div className="flex flex-row justify-content-center">
                                    {data?.documents?.map((data, index) => {
                                        return (
                                            <>
                                                <div className="" key={index}>
                                                    {data.type === "cnic" && <p>{data.comment}</p>}
                                                </div>
                                            </>
                                        );
                                    })}
                                </div>
                            );
                        }}
                    />
                    <Column
                        className="maker_colm"
                        field=""
                        header="Action"
                        body={(data) => {
                            return (
                                <div className="flex flex-row justify-content-center">
                                    {permissions?.edit && (
                                        <Button
                                            tooltip="Request To Re-Submit"
                                            icon="pi pi-clone"
                                            tooltipOptions={{ position: "top" }}
                                            className="edit m-2 p-button-success p-button-rounded"
                                            onClick={() => {
                                                resubmitDocument(data._id, "cnic");
                                            }}
                                        />
                                    )}
                                </div>
                            );
                        }}
                    />
                </DataTable>
                <DataTable responsiveLayout="scroll" value={[data]} key="_id" className="col-6 p-header-custom p-img-custom">
                    <Column
                        className="maker_colm"
                        field="documents"
                        header="License Images"
                        body={(data) => {
                            return (
                                <div className="flex flex-row justify-content-center">
                                    {data?.documents?.map((imgSrc, index) => {
                                        return (
                                            <>
                                                <div className="" key={index}>
                                                    {imgSrc.type === "license" && imgSrc?.frontImage && <Image height="100px" width="100px" className="ml-2" style={{ objectFit: "contain" }} src={`${appUrl.baseUrl}${imgSrc.frontImage}`} template="Preview" preview alt="Image Text" />}
                                                    {imgSrc.type === "license" && imgSrc?.backImage && <Image height="100px" width="100px" className="ml-2" style={{ objectFit: "contain" }} src={`${appUrl.baseUrl}${imgSrc.backImage}`} template="Preview" preview alt="Image Text" />}
                                                </div>
                                            </>
                                        );
                                    })}
                                </div>
                            );
                        }}
                    />
                    <Column
                        className="maker_colm"
                        field="status"
                        header="Status"
                        body={(data) => {
                            return (
                                <div className="flex flex-row justify-content-center">
                                    {data?.documents?.map((data, index) => {
                                        return (
                                            <>
                                                <div className="" key={index}>
                                                    {data.type === "license" && <p>{data.status}</p>}
                                                </div>
                                            </>
                                        );
                                    })}
                                </div>
                            );
                        }}
                    />
                    <Column
                        className="maker_colm"
                        field="comment"
                        header="Comment"
                        body={(data) => {
                            return (
                                <div className="flex flex-row justify-content-center">
                                    {data?.documents?.map((data, index) => {
                                        return (
                                            <>
                                                <div className="" key={index}>
                                                    {data.type === "license" && <p>{data.comment}</p>}
                                                </div>
                                            </>
                                        );
                                    })}
                                </div>
                            );
                        }}
                    />
                    <Column
                        className="maker_colm"
                        field=""
                        header="Action"
                        body={(data) => {
                            return (
                                <div className="flex flex-row justify-content-center">
                                    {permissions?.edit && (
                                        <Button
                                            tooltip="Request To Re-Submit"
                                            icon="pi pi-clone"
                                            tooltipOptions={{ position: "top" }}
                                            className="edit m-2 p-button-success p-button-rounded"
                                            onClick={() => {
                                                resubmitDocument(data._id, "license");
                                            }}
                                        />
                                    )}
                                </div>
                            );
                        }}
                    />
                </DataTable>
            </div>
        </>
    );
}
