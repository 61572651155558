import Axios from "axios";
import appUrl from "../../app/features/constants/appUrl";
import { axiosApi } from "../../app/features/constants/axios_api";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

const getDashboardReducer = createSlice({
    name: "getDashboard",
    initialState: {},
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getDashboardData.pending, (state, action) => {
                return { ...state, loading: true };
            })
            .addCase(getDashboardData.fulfilled, (state, action) => {
                return { ...state, loading: false, data: action.payload };
            })
            .addCase(getDashboardData.rejected, (state, action) => {
                return {
                    ...state,
                    loading: false,
                    error: action.payload,
                };
            });
        builder
            .addCase(getDashboardStat.pending, (state, action) => {
                return { ...state, statLoading: true };
            })
            .addCase(getDashboardStat.fulfilled, (state, action) => {
                return { ...state, statLoading: false, data: action.payload, loading: false };
            })
            .addCase(getDashboardStat.rejected, (state, action) => {
                return {
                    ...state,
                    statLoading: false,
                    statError: action.payload,
                };
            });
    },
});

export default getDashboardReducer.reducer;

// Thunks
export const getDashboardData = createAsyncThunk("getDashboard/fetch", async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosApi.get(appUrl.baseUrl + appUrl.getDashboardData, body);
        // //console.log("dashboards old",data)
        return fulfillWithValue(data.data);
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.msg ? error.response.data.msg : error.message);
    }
});

export const getDashboardStat = createAsyncThunk("getDashboard/get", async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        let url = "";
        if (body?.corporateId) {
            url = appUrl.baseUrl + appUrl.getDashboardCorporateStat + `?startDate=${body.startDate}&endDate=${body.endDate}&corporate=${body?.corporateId}`;
        } else if (body) {
            url = appUrl.baseUrl + appUrl.getDashboardStat + `?startDate=${body.startDate}&endDate=${body.endDate}`
        }
        else {
            url = appUrl.baseUrl + appUrl.getDashboardStat;
        }
        const { data } = await axiosApi.get(url);
        // //console.log("dashboards Stat updating on date change",data)
        return fulfillWithValue(data.data);
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.msg ? error.response.data.msg : error.message);
    }
});
