import Axios from "axios";
import appUrl from "../../app/features/constants/appUrl";
import { axiosApi } from "../../app/features/constants/axios_api";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");
const corporateCodeReducer = createSlice({
    name: "corporateCode",
    initialState: {},
    reducers: {
        resetAddEditCorporateCode(state, action) {
            return { ...state, addSuccess: undefined, editSuccess: undefined };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCorporateCodesList.pending, (state, action) => {
                return { ...state, loading: true };
            })
            .addCase(getCorporateCodesList.fulfilled, (state, action) => {
                return { ...state, loading: false, data: action.payload.data, count: action.payload.count };
            })
            .addCase(getCorporateCodesList.rejected, (state, action) => {
                return {
                    ...state,
                    loading: false,
                    error: action.payload,
                };
            });

        builder
            .addCase(addCorporateCode.pending, (state, action) => {
                return { ...state, addLoading: true };
            })
            .addCase(addCorporateCode.fulfilled, (state, action) => {
                return { ...state, addLoading: false, addData: action.payload, addSuccess: true };
            })
            .addCase(addCorporateCode.rejected, (state, action) => {
                return {
                    ...state,
                    addLoading: false,
                    addError: action.payload,
                    addSuccess: false,
                };
            });
        builder
            .addCase(editCorporateCode.pending, (state, action) => {
                return { ...state, editLoading: true };
            })
            .addCase(editCorporateCode.fulfilled, (state, action) => {
                return { ...state, editLoading: false, editData: action.payload, editSuccess: true };
            })
            .addCase(editCorporateCode.rejected, (state, action) => {
                return {
                    ...state,
                    editLoading: false,
                    error: action.payload,
                    editSuccess: false,
                };
            });
        builder
            .addCase(getGoogleAddress.pending, (state, action) => {
                return { ...state, loading: true };
            })
            .addCase(getGoogleAddress.fulfilled, (state, action) => {
                return { ...state, loading: false, address: action.payload.data };
            })
            .addCase(getGoogleAddress.rejected, (state, action) => {
                return {
                    ...state,
                    loading: false,
                    error: action.payload,
                };
            });
    },
});

export default corporateCodeReducer.reducer;
export const { resetAddEditCorporateCode } = corporateCodeReducer.actions;

// Thunks
export const getCorporateCodesList = createAsyncThunk("corporateCode/fetch", async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosApi.get(appUrl.baseUrl + appUrl.getCorporateCodesList + `/?pageNumber=${body.pageNumber}&text=${body.text}&perPageRecords=${body.perPageRecords}`);
        return fulfillWithValue({ data: data.data, count: data.count });
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.msg ? error.response.data.msg : error.message);
    }
});
export const addCorporateCode = createAsyncThunk("corporateCode/add", async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosApi.post(appUrl.baseUrl + appUrl.addCorporateCode, body);
        return fulfillWithValue(data.data);
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.msg ? error.response.data.msg : error.message);
    }
});
export const editCorporateCode = createAsyncThunk("corporateCode/edit", async ({id , body }, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosApi.patch(appUrl.baseUrl + appUrl.editCorporateCode + `/?id=${id}`, body);
        return fulfillWithValue(data.data);
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.msg ? error.response.data.msg : error.message);
    }
});

export const getGoogleAddress = createAsyncThunk("googleAddress/fetch", async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosApi.get(appUrl.baseUrl + appUrl.getGoogleAddress + `?text=${body.text}`);

        return fulfillWithValue({ data: data.data });
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.msg ? error.response.data.msg : error.message);
    }
});
