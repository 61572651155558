import React, { useEffect } from "react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useDispatch, useSelector } from "react-redux";
import { getBlockedUserList } from "../../../../../redux/slices/users_slice";
export default function BlockedUsers() {
    //redux
    const getUserReducer = useSelector((state) => state.usersList);
    const { data } = getUserReducer;

    //react hooks
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getBlockedUserList());
    }, []);

    const userTypeTemp = (rowData) => {
        return <div className="">{rowData.userType === "3" ? "Both" : rowData.userType === "2" ? "Passenger" : "Owner"}</div>;
    };

    const firstNameTemp = (rowData) => {
        const name = rowData.firstName;
        ////console.log(name)
        return <div>{name.charAt(0).toUpperCase() + name.slice(1)}</div>;
    };
    const lastNameTemp = (rowData) => {
        const name = rowData.lastName;
        ////console.log(name)
        return <div>{name.charAt(0).toUpperCase() + name.slice(1)}</div>;
    };

    return (
        <>
            <div className="card">
                <div className="grid pb-3">
                    <div className="col-12 md:col-6">
                        <h4>Blocked Users</h4>
                    </div>
                    <div className="col-12 md:col-6">
                        <div className="mb-9 text-right">
                            {/* <Button
                                label="Add Role"
                                type="submit"
                                className="mr-3 p-button-success p-button-rounded p-button-sm custom___width mb_width"
                                onClick={() => {
                                    setEditCategory(null);
                                    onClick("showAddEditCategoryDialog");
                                }}
                            /> */}
                        </div>
                    </div>
                </div>

                <DataTable filter rows={50} paginator value={data} responsiveLayout="scroll" emptyMessage="No record found." key="_id" globalFilterFields={["firstName", "lastName", "cnic", "mobile", "gender", "type"]}>
                    <Column className="maker_colm" style={{ minWidth: "12rem", padding: "1rem" }} body={firstNameTemp} header="First Name" />
                    <Column className="maker_colm" style={{ minWidth: "12rem", padding: "1rem" }} body={lastNameTemp} header="Last Name" />
                    <Column className="maker_colm" style={{ minWidth: "12rem", padding: "1rem" }} field="cnic" header="CNIC" />
                    <Column className="maker_colm" style={{ minWidth: "12rem", padding: "1rem" }} field="mobile" header="Mobile" />
                    <Column className="maker_colm" style={{ minWidth: "12rem", padding: "1rem" }} body={userTypeTemp} header="Type" />
                    <Column className="maker_colm" style={{ minWidth: "12rem", padding: "1rem" }} field="gender" header="Gender" />
                    {/*<Column className="maker_colm" filter body={statusBodyDriver} header="Status" /> */}
                    {/* <Column className="maker_colm" body={statusBody} header="Status" /> */}
                    {/* <Column className="maker_colm" header="Action" body={actionTemplate} /> */}
                </DataTable>
            </div>
        </>
    );
}
