import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomLoading from "../../../components/custom_loading";
import CustomInputField from "../../../components/custom_input_filed";
import { addAdminUser, editAdminUser, getUserRoles } from "../../../../../redux/slices/admin_user_slice";
import { toast } from "react-toastify";
import { InputText } from "primereact/inputtext";
import { axiosApi } from "../../../constants/axios_api";
import appUrl from "../../../constants/appUrl";

export default function AddEditAdminUserDialog({ onHide, editUser }) {
    const getUserReducer = useSelector((state) => state.getUser);
    const { roles, loading, addSuccess, addLoading, editSuccess, editError, editLoading, addError } = getUserReducer;
    const [profileImage, setProfileImage] = useState("");
    const [admin_roles, setRoles] = useState([]); // Use local state for roles

    useEffect(() => {
        if (editError) {
            toast.error(editError);
        }
        if (addError) {
            toast.error(addError);
        }
        if (editSuccess) {
            toast.success("User updated successfuly");
        }
        if (addSuccess) {
            toast.success("User added successfuly");
        }
    }, [addError, editSuccess, addSuccess, editError]);
    const dispatch = useDispatch();
    const statusList = [
        {
            code: true,
            name: "Active",
        },
        {
            code: false,
            name: "InActive",
        },
    ];

    const loadEditData = () => {
        // formik.setFieldValue("status", editUser['status']);
        formik.setFieldValue("userName", editUser["userName"]);
        formik.setFieldValue("email", editUser["email"]);
        formik.setFieldValue("mobile", editUser["mobile"]);
        formik.setFieldValue("status", editUser["status"]);
        // console.log(editUser.status)
        formik.setFieldValue("role", editUser["role"]["_id"]);
    };

    const getallRoles = async () => {
        const response = await axiosApi.get(`${appUrl.baseUrl}api/web/role/roles`);
        setRoles(response?.data?.data);
    };



    useEffect(() => {
        getallRoles(); // Fetch roles on component mount
        if (editUser != null) {
            loadEditData();
        }
    }, []);

    const validationSchema = Yup.object().shape({
        userName: Yup.string().max(35, "Maximum length 35 allowed").required("This field is required."),
        password: editUser == null ? Yup.string().max(50, "Maximum length 50 allowed").required("This field is required.").min(8, "Minimum length should be 8") : Yup.string().min(8, "Minimum length should be 8"),

        email: Yup.string()
            .email("Invalid email address format")
            .required("This field is required.")
            .matches(/@rahper\.com$/, "Input must end with @rahper.com"),
        mobile: Yup.string()
            .required("This field is required.")
            .matches(/^03[0-9]{9}$/, "Number should start with 03 and length should be 11")
            .max(11, "Maximum length 11 allowed")
            .nullable(),
        role: Yup.string().required("Role is required"),
    });

    useEffect(() => {
        if (!editUser && addSuccess) onHide();
        if (editUser && editSuccess) onHide();
    }, [addSuccess, editSuccess]);
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            userName: "",
            password: "",
            email: "",
            mobile: "",
            role: "",
            status: "",
        },

        onSubmit: async (data) => {
            if (profileImage) {
                data["profileImage"] = profileImage;
            }
            if (editUser == null) {
                dispatch(addAdminUser(data));
            } else {
                data["userId"] = editUser._id;
                dispatch(editAdminUser(data));
            }
        },
    });
    useEffect(() => {
        const fetchRoles = async () => {
            try {
                // Check if a corporate is selected
                if (formik.values.corporate) {
                    // If corporate is selected, call corporate-specific roles API
                    const response = await axiosApi.get(`${appUrl.baseUrl}api/web/role/corporateAll`);
                    dispatch(getUserRoles(response.data));
                } else {
                    // If no corporate is selected, call the default roles API
                    dispatch(getUserRoles());
                }
            } catch (error) {
                console.error("Error fetching roles:", error);
            }
        };
    
        fetchRoles();
    }, [formik.values.corporate, dispatch]);
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = (e) => {
            setProfileImage(e.target.result);
        };

        reader.readAsDataURL(file);
    };

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                {loading === true ? (
                    <CustomLoading />
                ) : (
                    <div className="grid">
                        <div className="col-12">
                            <div className="p-fluid grid">
                                <div className="col-12 md:col-6">
                                    <div className="field">
                                        {/* keyfilter={/^[a-z0-9_-]+$/}*/}
                                        <CustomInputField iden={"userName"} formik={formik} type="text" autoComplete="off" />
                                    </div>
                                </div>
                                <div className="col-12 md:col-6">
                                    <div className="field">
                                        <CustomInputField iden={"password"} formik={formik} type="password" placeholder="Please enter a password" feedback={false} />
                                    </div>
                                </div>
                                <div className="col-12 md:col-6">
                                    <div className="field">
                                        <CustomInputField iden={"email"} formik={formik} type="text" />
                                    </div>
                                </div>
                                <div className="col-12 md:col-6">
                                    <div className="field">
                                        <CustomInputField iden={"mobile"} formik={formik} type="text" />
                                    </div>
                                </div>
                                <div className="col-12 md:col-6">
                                    <div className="field">
                                        <CustomInputField iden={"role"} formik={formik} type="dropdown" optionValue="_id" optionLabel="role" options={admin_roles} />
                                    </div>
                                </div>

                                {editUser != null && (
                                    <div className="col-12 md:col-6">
                                        <div className="field">
                                            <CustomInputField iden={"status"} formik={formik} type="dropdown" optionLabel="name" optionValue="code" options={statusList} />
                                        </div>
                                    </div>
                                )}
                                {editUser == null && (
                                    <div className="col-12 md:col-6">
                                        <div className="field">
                                            <label>Profile Image</label>
                                            <InputText id="profileImage" placeholder="Upload Profile Image" name="profileImage" type="file" accept="images/*" onChange={handleFileChange} />{" "}
                                        </div>
                                    </div>
                                )}

                                <div className="col-12 md:col-12">
                                    <div className="button_class-modal">
                                        <Button loading={editUser == null ? addLoading : editLoading} type="submit" label={editUser === null ? "Add" : "Update"} className="p-button-success btn__default_modal p-button-rounded" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </form>
        </>
    );
}
