import { addAdminUserReducer, editAdminUserReducer, getRolesReduces } from "./redux/reducers/admin_user_reducers";
import { updateDriverDocumentsStatusReducer } from "./redux/reducers/driver_reducers";
import { configureStore } from "@reduxjs/toolkit";
import { getAllRidesReducer, getRideDetailsReducer, getUserRidesReducer } from "./redux/reducers/rides_reducer";
import docuementVerificationReducer from "./redux/slices/documentVerificationSlice";
import getReceiptDetailsRedcuer from "./redux/slices/get_receipt_details_slice";
import corporateCodeReducer from "./redux/slices/corporate_code_slice";
import adsReducer from "./redux/slices/ads_slice";
import faqs_slice from "./redux/slices/faqs_slice";
import faqsCategory from "./redux/slices/faqs_category_slice";
import ratings_slice from "./redux/slices/ratings_slice";
import notification_slice from "./redux/slices/notification_slice";
import vehicle_verification_slice from "./redux/slices/vehicle_verification_slice";
import driversReducer from "./redux/slices/drivers_slice";
import usersReducer from "./redux/slices/users_slice";
import fare_management_slice from "./redux/slices/fare_management_slice";
import version_control_slice from "./redux/slices/version_control_slice";
import rides_slice from "./redux/slices/rides_slice";
import admin_user_slice from "./redux/slices/admin_user_slice";
import adminUserSlice from "./redux/slices/admin_user_slice";
import dashboard_slice from "./redux/slices/dashboard_slice";
import vehicle_type_slice from "./redux/slices/vehicle_type_slice";
import vehicle_makers_slice from "./redux/slices/vehicle_makers_slice";
import vehicle_models_slice from "./redux/slices/vehicle_models_slice";
import tutorials_slice from "./redux/slices/tutorials_slice";
import tutorials_category_slice from "./redux/slices/tutorials_category_slice";
import accounts_slice from "./redux/slices/accounts_slice";
import modules_slice from "./redux/slices/modules_slice";
import roles_slice from "./redux/slices/roles_slice";
import vehicle_model_by_id_slice from "./redux/slices/vehicle_model_by_id_slice";
import reset_password_slice from "./redux/slices/reset_password_slice";
import vehicle_list_by_id_slice from "./redux/slices/vehicle_list_by_id_slice";
import socket_slice from "./redux/slices/socket_slice";
import referrer_slice from "./redux/slices/referrer_slice";
import sms_slice_log from "./redux/slices/sms_slice_log";
import permission_slice from "./redux/slices/permission_slice";
import AddCorporateSlice from "./redux/slices/AddCorporateSlice";
const user = localStorage.getItem("user");

const initialState = {
    loginUser: {
        user: user ? JSON.parse(user) : undefined,
    },
};

const store = configureStore({
    reducer: {
        loginUser: admin_user_slice,
        usersList: usersReducer,
        getUserRides: getUserRidesReducer,
        getRideDetails: getRideDetailsReducer,
        getAllRides: getAllRidesReducer,
        ridesDetails: rides_slice,
        getDashboardData: dashboard_slice,
        getDashboardStat: dashboard_slice,
        addAdminUser: addAdminUserReducer,
        editAdminUser: editAdminUserReducer,
        getUserRoles: getRolesReduces,
        getUser: adminUserSlice,
        documentVerifications: docuementVerificationReducer,
        receiptDetails: getReceiptDetailsRedcuer,
        corporateCode: corporateCodeReducer,
        newCorporateCode:AddCorporateSlice,
        adsList: adsReducer,
        fareManagement: fare_management_slice,
        versionControl: version_control_slice,
        faqsList: faqs_slice,
        faqCategory: faqsCategory,
        userRating: ratings_slice,
        notificationsList: notification_slice,
        vehicleDocuments: vehicle_verification_slice,
        vehicleListByUserId: vehicle_list_by_id_slice,
        updateDriverDocumentsStatus: updateDriverDocumentsStatusReducer,
        driversList: driversReducer,
        vehicleType: vehicle_type_slice,
        vehicleMakers: vehicle_makers_slice,
        vehicleModel: vehicle_models_slice,
        vehicleModelById: vehicle_model_by_id_slice,
        tutorialList: tutorials_slice,
        tutorialCategoryList: tutorials_category_slice,
        accountsList: accounts_slice,
        modulesList: modules_slice,
        rolesList: roles_slice,
        socketConnectionSlice: socket_slice,
        resetPassword: reset_password_slice,
        referrer: referrer_slice,
        smsLogs: sms_slice_log,
        permissionList: permission_slice,
    },
    preloadedState: initialState,
});

export default store;
