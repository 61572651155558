import React, { useEffect, useState, useRef } from "react";
import { Button } from "primereact/button";
import { FilterMatchMode } from "primereact/api";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Avatar } from "primereact/avatar";
import { DataTable } from "primereact/datatable";
import { useDispatch, useSelector } from "react-redux";
import { getAccountsList } from "../../../../../redux/slices/accounts_slice";
import { InputText } from "primereact/inputtext";
import UserAccountProfileDialog from "../components/user_account_profile";
import moment from "moment";

export default function PaidAccountScreen({ permissions }) {
    const AccountReducer = useSelector((state) => state.accountsList);
    const { data: list } = AccountReducer;
    const [showProfileDialog, setProfileDialog] = useState(false);

    const [expandedRows, setExpandedRows] = useState(null);
    const dispatch = useDispatch();
    const [editDialog, setEditDialog] = useState(false);
    const [editUser, setEditUser] = useState(null);
    const [globalFilterValue, setGlobalFilterValue] = useState("");
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    const dt = useRef(null);

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        setGlobalFilterValue(value);
    };

    const dialogFuncMap = {
        editDialog: setEditDialog,
        showProfileDialog: setProfileDialog,
    };
    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);
    };

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    };
    const methodBodyTemp = (rowData) => {
        return <div className={rowData.method === "unpaid" ? "bg_red" : rowData.method === "zindigi" ? "bg_zindigi" : "bg_green"}>{rowData.method}</div>;
    };
    const createdAtTemplate = (rowData) => {
        return <>{moment(rowData.createdAt).format("MMMM Do YYYY, h:mm a")}</>;
    };
    const rowExpansionTemplate = (rowData) => {
        return (
            <>
                <h5>Passengers</h5>
                <DataTable showGridlines={true} value={rowData.history}>
                    <Column field="passenger.firstName" header="First Name" />

                    <Column field="passenger.lastName" header="Last Name " />
                    <Column field="passenger.mobile" header="Mobile " />
                    <Column field="passenger.email" header="Email " />
                    <Column field="schedule.startPoint.placeName" header="Start Point" />
                    <Column field="schedule.endPoint.placeName" header="End Point" />
                    <Column body={createdAtTemplate} header="Created At" style={{ minWidth: "12rem" }} />
                    <Column body={methodBodyTemp} header="Method" />
                    <Column field="fare" header="Fare(PKR)" style={{ textAlign: "center" }} />
                </DataTable>
            </>
        );
    };
    const allowExpansion = (rowData) => {
        return <>{rowData.length > 0}</>;
    };
    useEffect(() => {
        dispatch(getAccountsList({ status: "paid", text: globalFilterValue }));
    }, [globalFilterValue]);

    const exportCSV = () => {
        dt.current.exportCSV();
    };

    const actionTemplate = (rowData) => {
        return (
            <div className="Edit_Icon">
                {permissions?.edit && (
                    <Avatar
                        icon="pi pi-user"
                        className="m-2 p-button-rounded"
                        size="large"
                        shape="circle"
                        tooltipOptions={{ position: "top" }}
                        tooltip="View Profile"
                        onClick={() => {
                            setEditUser(rowData);
                            onClick("showProfileDialog");
                        }}
                    />
                )}
            </div>
        );
    };

    return (
        <>
            <Dialog header="Profile" position="center" className="text-center" visible={showProfileDialog} style={{ width: "60vw", height: "" }} onHide={() => onHide("showProfileDialog")}>
                <div>{editUser != null && <UserAccountProfileDialog permissions={permissions} userId={editUser._id} user={editUser} userType="1" />}</div>
            </Dialog>

            <div className="card">
                <div className="grid pb-3">
                    <div className="col-12 md:col-6">
                        <h4>Paid Details</h4>
                    </div>
                    <div className="col-12 md:col-6">
                        <div className="mb-9 text-right">
                            <span className="p-input-icon-right mr-3">
                                <InputText className="mbl_view" value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search" />
                            </span>
                            <Button style={{ fontSize: "14px" }} className="p-button-success p-button-rounded p-button-sm custom___width" label="Export to Excel" iconPos="right" onClick={exportCSV} />
                        </div>
                    </div>
                </div>
                <h5>Owners</h5>
                <DataTable
                    filter
                    expandedRows={expandedRows}
                    rows={50}
                    paginator
                    value={list}
                    responsiveLayout="scroll"
                    onRowToggle={(e) => {
                        setExpandedRows(e.data);
                    }}
                    rowExpansionTemplate={rowExpansionTemplate}
                    filters={filters}
                    ref={dt}
                    globalFilterFields={["driver.firstName", "driver.lastName", "driver.cnic", "driver.email", "driver.zindigiWallet.zindigiWalletNumber"]}
                >
                    <Column className="maker_colm" expander={allowExpansion} style={{ width: "3em" }} />
                    <Column className="maker_colm" field="driver.firstName" header="First Name" />
                    <Column className="maker_colm" field="driver.lastName" header="Last Name" />
                    <Column className="maker_colm" field="driver.mobile" header="Mobile" />
                    <Column className="maker_colm" field="driver.cnic" header="CNIC" />
                    <Column className="maker_colm" field="driver.email" header="Email" />
                    <Column className="maker_colm" field="driver.zindigiWallet.zindigiWalletNumber" header="Zindigi Wallet" />
                    <Column className="maker_colm" field="total" header="Total Amount(PKR)" style={{ textAlign: "center" }} />
                    <Column className="maker_colm" header="Action" body={actionTemplate} />
                </DataTable>
            </div>
        </>
    );
}
