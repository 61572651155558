import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dialog } from "primereact/dialog";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";
// import EditVehicleDialog from "../components/add_edit_vehicles_dialog";
import { Dropdown } from "primereact/dropdown";
import CustomPageHeader from "../../../components/custom_page_header";
// import { getUserVehiclesById } from "../../../../../redux/slices/vehicle_verification_slice";
import { getUserVehiclesById } from "../../../../../redux/slices/vehicle_list_by_id_slice";
// import EditVehicleDialog from "../../verification/components/add_edit_vehicles_dialog";
import { useParams } from "react-router-dom";

export default function UserVehiclesListScreen({ permissions }) {
    //redux
    const { data, loading } = useSelector((state) => state.vehicleListByUserId);
    // const { data, loading } = useSelector((state) => state.vehicleDocuments);
    const dispatch = useDispatch();

    //usestate hooks
    const [selectedCategory, setSelectedCategory] = useState("pending");
    const [editData, setEditData] = useState(null);
    const [showEditDialog, setEditDialog] = useState(false);
    const [globalFilterValue, setGlobalFilterValue] = useState("");

    //params
    const param = useParams();
    const { userId, type } = param;

    //hooks
    useEffect(() => {
        if (userId !== "") {
            let body = {
                userId: userId,
                isDriver: type === "1",
            };
            // //console.log(body)
            dispatch(getUserVehiclesById(body));
        }
    }, [userId, type, dispatch]);
    //dropdown status list
    // const statusList = [
    //     { status: "Pending", value: "pending" },
    //     { status: "Rejected", value: "rejected" },
    //     { status: "Verified", value: "verified" },
    // ];

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters["global"].value = value;
        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    // dialog
    const dialogFuncMap = {
        showEditDialog: setEditDialog,
    };

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);
    };

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    };
    // const modelTemp = (rowData) => {
    //     return <div>{rowData.userVehicle?.map((data) => {
    //         return data?.model?.model
    //     })}</div>;
    // };
    // const statusTemplate = (rowData) => {
    //     const status = rowData.documents[0].status;
    //     return <div>{status}</div>;
    // };
    // const actionTemplate = (rowData) => {
    //     return (
    //         <div className="Edit_Icon">
    //             <Button
    //                 tooltip="Documents"
    //                 icon="pi pi-copy"
    //                 tooltipOptions={{ position: "top" }}
    //                 className="edit m-2 p-button-success p-button-rounded"
    //                 onClick={() => {
    //                     setEditData(rowData);
    //                     onClick("showEditDialog");
    //                 }}
    //             />
    //         </div>
    //     );
    // };

    //useeffect hooks
    // useEffect(() => {
    //     dispatch(getVehicleDocumentsList(selectedCategory));
    // }, [selectedCategory, dispatch]);

    return (
        <>
            {/* <Dialog header="Documents" visible={showEditDialog} onHide={() => onHide("showEditDialog")} breakpoints={{ "960px": "75vw", "640px": "100vw" }} style={{ width: "70vw" }}>
                <EditVehicleDialog editData={editData} onHide={() => onHide("showEditDialog")} />
            </Dialog> */}
            <div className="card">
                <div className="flex flex-row justify-content-between align-items-baseline pb-3">
                    <CustomPageHeader title={"Vehicles List "} />
                    <div className="mb-9 text-right">
                        <span className="p-input-icon-right">
                            <InputText className="mb-3 mr-2" value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search" />
                            {/* <Dropdown className="" value={selectedCategory} options={statusList} optionLabel="status" optionValue="value" onChange={(e) => setSelectedCategory(e.value)} placeholder="Select a Status" /> */}
                        </span>
                    </div>
                </div>
                {data && (
                    <div>
                        <DataTable rows={10} paginator responsiveLayout="scroll" value={data[0].userVehicle} filters={filters} emptyMessage="No record found.">
                            {/* {//console.log("datatable", data[0].userVehicle)} */}
                            <Column className="maker_colm" filter field="model.model" header="Model" />
                            <Column className="maker_colm" filter field="registrationNumber" header="Registration Number" />
                            <Column className="maker_colm" filter field="registrationCity.city" header="Registration City" />
                            <Column className="maker_colm" filter field="registrationCity.province.name" header="Registration Province" />
                            <Column className="maker_colm" filter field="color.color" header="Color" />
                            <Column className="maker_colm" filter field="seatingCapacity" header="Seating Capacity" />
                            <Column className="maker_colm" filter field="year" header="Year" />
                            {/* <Column className="maker_colm" filter field={statusTemplate} header="Status" /> */}
                            {/* <Column className="maker_colm" header="Action" body={actionTemplate} /> */}
                        </DataTable>
                    </div>
                )}
            </div>
        </>
    );
}
