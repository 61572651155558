import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Avatar } from "primereact/avatar";
import { Dialog } from "primereact/dialog";
import EditUserDialog from "../../users/components/edit_passenger_dialog";
import { Image } from "primereact/image";
import appUrl from "../../../constants/appUrl";
import { getDocumentsVerifications } from "../../../../../redux/slices/documentVerificationSlice";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";
import { toast } from "react-toastify";
import VerificationDialog from "../../drivers/components/licence_verification_dialog";
import CustomPageHeader from "../../../components/custom_page_header";
import { Paginator } from "primereact/paginator";
import moment from "moment";
import UserAccountProfileDialog from "../../accounts/components/user_account_profile";
import VideoCall from "../../videoCall/pages";
import { Dropdown } from "primereact/dropdown";
import male from "../../../../../assets/img/male.png";
import female from "../../../../../assets/img/female.png";
import owner from "../../../../../assets/img/owner.png";
import passenger from "../../../../../assets/img/passenger.png";

export default function PersonalScreen({ permissions }) {
    //redux
    const driversListReducer = useSelector((state) => state.documentVerifications);
    const { list, loading, count, updateSuccess } = driversListReducer;
    const dispatch = useDispatch();

    //hooks
    const [showEditDialog, setEditDialog] = useState(false);
    const [flag, setFalg] = useState("unverified");
    const [showCallDialog, setCallDialog] = useState(false);
    const [showProfileDialog, setProfileDialog] = useState(false);
    const [editUser, setEditUser] = useState(undefined);
    const [editUserId, setEditUserId] = useState(undefined);
    const [editDocument, setEditDocument] = useState(undefined);
    const [showVerificationDialog, setVerificationDialog] = useState(false);
    const [globalFilterValue, setGlobalFilterValue] = useState("");
    const [first, setFirst] = useState(0);
    const [pageNumber, setPageNumber] = useState(0);
    const [rows, setRows] = useState(50);
    const [defaultImage] = useState("app/black-watch.jpg");

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters["global"].value = value;
        setFilters(_filters);
        setGlobalFilterValue(value);
    };
    useEffect(() => {
        dispatch(getDocumentsVerifications({ pageNumber: 0, flag, perPageRecords: rows }));
    }, [flag]);

    // dialog
    const dialogFuncMap = {
        showEditDialog: setEditDialog,
        showProfileDialog: setProfileDialog,
        showVerificationDialog: setVerificationDialog,
        showCallDialog: setCallDialog,
    };
    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);
    };
    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    };

    const statusList = [
        { status: "Verified", value: "verified" },
        { status: "Unverified", value: "unverified" },
        { status: "Other", value: "other" },
    ];
    //components
    // const userTypeTemp = (rowData) => {
    //     return <div className="">{rowData.userType === "3" ? "Both" : rowData.userType === "2" ? "Passenger" : "Driver"}</div>;
    // };

    // const activeStatusBody = (rowData) => {
    //     return <div className={rowData.active.status ? "green" : "red"}>{rowData.active.status ? "Active" : "In Active"}</div>;
    // };
    const rowClass = (data) => {
        if (data?.active?.status === true) {
            return {
                "bg-success": true, // Green background for active
            };
        } else if (data?.active?.status === false) {
            return {
                "bg-danger": true, // Red background for inactive
            };
        } else {
            return {}; // No class if no status
        }
    };

    const genderTemp = (rowData) => {
        const gender = rowData.gender;
        return <div>{gender === "female" ? <img src={female} style={{ width: "30px", height: "30px" }} className="ml-3" /> : <img src={male} style={{ width: "30px", height: "30px" }} className="ml-3" />}</div>;
    };

    const userTypeTemp = (rowData) => {
        if (rowData.userType === "3") {
            return (
                <div className="flex">
                    <img src={passenger} title="Passenger" style={{ height: "40px", width: "40px" }} />
                    <img src={owner} title="Owner" style={{ height: "30px", width: "40px" }} />
                </div>
            );
        } else if (rowData.userType === "2") {
            return (
                <div className="">
                    <img src={passenger} title="Passenger" style={{ height: "50px", width: "50px" }} />
                </div>
            );
        } else if (rowData.userType === "1") {
            return (
                <div className="">
                    <img src={owner} title="Owner" style={{ height: "40px", width: "50px" }} />
                </div>
            );
        } else {
            return "";
        }
    };

    const actionTemplate = (rowData) => {
        return (
            <div className="Edit_Icon">
                <Avatar
                    icon="pi pi-user"
                    className="m-2 p-button-success p-button-rounded"
                    size="large"
                    shape="circle"
                    tooltipOptions={{ position: "top" }}
                    tooltip="View Profile"
                    onClick={() => {
                        setEditUser(rowData);
                        onClick("showProfileDialog");
                    }}
                />
                <Avatar
                    icon="pi pi-phone"
                    className="m-2 p-button-rounded"
                    size="large"
                    shape="circle"
                    style={{ backgroundColor: "#2196F3", color: "#ffffff" }}
                    tooltipOptions={{ position: "top" }}
                    tooltip="Make Call"
                    onClick={() => {
                        setEditUser(rowData);
                        onClick("showCallDialog");
                    }}
                />
            </div>
        );
    };

    const firstNameTemp = (rowData) => {
        const name = rowData.firstName;
        return <div>{name.charAt(0).toUpperCase() + name.slice(1)}</div>;
    };
    const lastNameTemp = (rowData) => {
        const name = rowData.lastName;
        return <div>{name.charAt(0).toUpperCase() + name.slice(1)}</div>;
    };

    // const createdAtTemplate = (rowData) => {
    //     return <>{moment(rowData?.createdAt).format("MMMM Do YYYY, h:mm a")}</>;
    // };

    // Custom header for Created At column
    const dateHeaderTemplate = () => (
        <>
            Created At
            <div style={{ fontSize: "0.8rem", color: "#6c757d" }}>DD/MM/YYYY</div>
        </>
    );

    // Custom template for Created At column
    const createdAtTemplate = (rowData) => {
        const formattedDate = moment(rowData?.createdAt).format("DD/MM/YYYY"); // Only date format
        const fullDate = moment(rowData?.createdAt).format("MMMM Do YYYY, h:mm a"); // Full date with time

        return (
            <span className="date-tooltip-container">
                <span className="date-tooltip">{formattedDate}</span>
                <span className="date-full-tooltip">{fullDate}</span>
            </span>
        );
    };

    const documentTemplate = (rowData) => {
        const cnicDoc = rowData.documents.find((doc) => {
            return doc.type == "cnic";
        });
        const licenceDoc = rowData.documents.find((doc) => {
            return doc.type == "license";
        });
        return (
            <div className="Edit_Icon">
                <Button
                    tooltip="License Verification"
                    icon="pi pi-clone"
                    tooltipOptions={{ position: "top" }}
                    className={
                        licenceDoc?.type === "license" && licenceDoc?.status === "pending"
                            ? "edit m-2 p-button-warning p-button-rounded"
                            : licenceDoc?.type === "license" && licenceDoc?.status === "rejected"
                            ? "edit m-2 p-button-danger p-button-rounded"
                            : licenceDoc?.type === "license" && licenceDoc?.status === "verified"
                            ? "edit m-2 p-button-green p-button-rounded"
                            : licenceDoc?.type === "license" && licenceDoc?.status === "re-submit"
                            ? "edit m-2 p-button-help p-button-rounded"
                            : "edit m-2 p-button-success p-button-rounded"
                    }
                    onClick={() => {
                        const doc = rowData.documents.find((item) => item.type === "license");
                        if (doc) {
                            setEditDocument(doc);
                            setEditUserId(rowData._id);
                            setEditUser(rowData);
                            onClick("showVerificationDialog");
                        } else {
                            toast.warn("No document found");
                        }
                    }}
                />
                <Button
                    tooltip="CNIC verification"
                    icon="pi pi-id-card"
                    tooltipOptions={{ position: "top" }}
                    className={
                        cnicDoc?.type === "cnic" && cnicDoc?.status === "pending"
                            ? "edit m-2 p-button-warning p-button-rounded"
                            : cnicDoc?.type === "cnic" && cnicDoc?.status === "rejected"
                            ? "edit m-2 p-button-danger p-button-rounded"
                            : cnicDoc?.type === "cnic" && cnicDoc?.status === "verified"
                            ? "edit m-2 p-button-green p-button-rounded"
                            : cnicDoc?.type === "cnic" && cnicDoc?.status === "re-submit"
                            ? "edit m-2 p-button-help p-button-rounded"
                            : "edit m-2 p-button-success p-button-rounded"
                    }
                    onClick={() => {
                        const doc = rowData.documents.find((item) => item.type === "cnic" || item.type === "cnic/id");
                        if (doc) {
                            setEditDocument(doc);
                            setEditUserId(rowData._id);
                            setEditUser(rowData);
                            onClick("showVerificationDialog");
                        } else {
                            toast.warn("No document found");
                        }
                    }}
                />
            </div>
        );
    };
    const onPageChange = (event) => {
        setFirst(event.first);
        setPageNumber(event.first / event.rows);
        setRows(event.rows);
        dispatch(getDocumentsVerifications({ pageNumber: event.first / event.rows, flag, perPageRecords: event.rows }));
    };

    return (
        <>
            <Dialog header="Update driver status" visible={showEditDialog} onHide={() => onHide("showEditDialog")} breakpoints={{ "960px": "75vw", "640px": "95vw" }} style={{ width: "70vw" }}>
                <EditUserDialog editUser={editUser} onHide={() => onHide("showEditDialog")} />
            </Dialog>
            <Dialog header={`Calling ${editUser?.firstName} ${editUser?.lastName}`} visible={showCallDialog} onHide={() => onHide("showCallDialog")} breakpoints={{ "960px": "75vw", "640px": "95vw" }} style={{ width: "auto", height: "100vh" }}>
                <VideoCall userToCall={`${editUser?.firstName} ${editUser?.lastName}`} userId={editUser?._id} callerName={"Verification Team"} onHide={() => onHide("showCallDialog")} />
            </Dialog>

            <Dialog header="Documents Verification" visible={showVerificationDialog} onHide={() => onHide("showVerificationDialog")} breakpoints={{ "960px": "75vw", "640px": "95vw" }} style={{ width: "70vw" }}>
                <VerificationDialog
                    user={editUser}
                    pageNumber={pageNumber}
                    flag={flag}
                    permissions={permissions}
                    userId={editUserId}
                    document={editDocument}
                    getDocumentsVerifications={getDocumentsVerifications}
                    onHide={() => {
                        onHide("showVerificationDialog");
                        setEditUser(null);
                    }}
                />
            </Dialog>
            <Dialog header="Profile" position="center" className="text-center" visible={showProfileDialog} breakpoints={{ "960px": "75vw", "640px": "98vw" }} style={{ width: "70vw", height: "" }} onHide={() => onHide("showProfileDialog")}>
                <div>{editUser != null && <UserAccountProfileDialog userId={editUser._id} user={editUser} userType="1" />}</div>
            </Dialog>

            <div className="card">
                <div className="grid">
                    <div className="col-12 md:col-6">
                        <CustomPageHeader title={"Personal Document Verification"} />
                    </div>
                    <div className=" col-12 md:col-6 mb-9 text-right">
                        <span className="p-input-icon-right">
                            {/* <InputText className="mb-3" value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search" /> */}
                            <Dropdown className="ml-3" value={flag} options={statusList} optionLabel="status" optionValue="value" onChange={(e) => setFalg(e.value)} placeholder="Select a Status" />
                        </span>
                    </div>
                </div>
                <DataTable rowClassName={rowClass} className="p-datatable-gridlines" emptyMessage="No record found." loading={loading} responsiveLayout="scroll" value={list} key="_id" filters={filters} globalFilterFields={["firstName", "lastName", "cnic", "mobile"]}>
                    <Column
                        className="maker_colm"
                        header="Image"
                        body={(data) => {
                            return (
                                <div>
                                    <Image imageStyle={{ borderRadius: "50%", width: "50px", height: "50px" }} src={data.profileImage ? `${appUrl.fileBaseUrl}${data?.profileImage}` : defaultImage} />
                                </div>
                            );
                        }}
                    />
                    <Column className="maker_colm" sortable body={firstNameTemp} header="First Name" />
                    <Column className="maker_colm" sortable body={lastNameTemp} header="Last Name" />
                    <Column className="maker_colm" sortable field="cnic" header="CNIC" />
                    <Column className="maker_colm" sortable field="mobile" header="Mobile" />
                    <Column className="maker_colm Action_col" sortable body={userTypeTemp} bodyClassName="text-center" header="Type" />
                    <Column className="maker_colm" sortable body={genderTemp} header="Gender" />
                    {/* <Column className="maker_colm" sortable style={{ minWidth: "8rem" }} body={createdAtTemplate} header="Created At" /> */}
                    <Column className="maker_colm" style={{ minWidth: "8rem" }} body={createdAtTemplate} header={dateHeaderTemplate} />
                    {/* <Column className="maker_colm" sortable body={activeStatusBody} header="Status" /> */}
                    <Column className="maker_colm" sortables body={documentTemplate} header="Documents" />
                    <Column className="maker_colm Action_col" header="Action" body={actionTemplate} />
                    {/* <Column body={actionTemplateAttach} header=" View Doc" /> */}
                </DataTable>

                <Paginator first={first} rows={rows} totalRecords={count} onPageChange={onPageChange} rowsPerPageOptions={[10, 20, 30, 50]} />
            </div>
        </>
    );
}
