import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React from "react";
import { useHistory } from "react-router-dom";
import { formateDateTime } from "../../../services/date_service";
import { useState } from "react";
import { Paginator } from "primereact/paginator";

export default function RideTableComponent({ rides, loading, userType, setPageNumber, count, flag, permissions }) {
    const history = useHistory();
    const [first, setFirst] = useState(0);
    const [page, setPage] = useState(0);

    const actionTemplate = (rowData) => {
        return (
            <div className="Edit_Icon">
                {permissions?.view && (
                    <Button
                        tooltip="View Details"
                        icon="pi pi-ellipsis-h"
                        tooltipOptions={{ position: "top" }}
                        onClick={() => {
                            if (userType === "1") {
                                history.push(`/api/user/drivers/ride/` + rowData._id);
                            } else {
                                history.push(`/api/user/passengers/ride/` + rowData._id);
                            }
                        }}
                        className="edit mr-2 p-button-success p-button-rounded"
                    />
                )}
            </div>
        );
    };

    const onPageChange = (event) => {
        setFirst(event.first);
        setPageNumber({ pageNumber: event.first / event.rows });
        setPage({ pageNumber: event.first / event.rows });
    };

    return (
        <div className="card">
            <DataTable loading={loading} responsiveLayout="scroll" value={rides} key="_id" emptyMessage="No record found.">
                <Column className="maker_colm" body={(data, props) => <div>{page * 50 + props.rowIndex + 1}</div>} filter header="Sr" />
                <Column className="maker_colm" filter field="startPoint.placeName" header="Pickup location" />
                <Column className="maker_colm" filter field="endPoint.placeName" header="Dropoff location" />
                <Column className="maker_colm" filter body={(data) => <div>{formateDateTime(data.date)}</div>} field="date" header="Date" />
                <Column className="maker_colm" filter field="bookedSeats" header="Booked seats" />
                <Column className="maker_colm" filter field="gender" header="Gender" />
                <Column className="maker_colm" filter field="status" header="Status" />

                <Column className="maker_colm" header="Action" body={actionTemplate} />
            </DataTable>
            <Paginator first={first} rows={50} totalRecords={count} onPageChange={onPageChange} />
        </div>
    );
}
