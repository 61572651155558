import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
import "primeicons/primeicons.css";
import { confirmDialog } from "primereact/confirmdialog";
import TutorialItem from "./tutorial_item";
import { Toast } from "primereact/toast";
import { useSelector } from "react-redux";
export default function TutorialCategory({ categoryOnClick, onSelectedCategory, cat, deleteOnClick }) {
    //redux
    const { data: list } = useSelector((state) => state.tutorialList);
    //hooks
    const [expanded, setExpanded] = useState(false);
    const toast = useRef(null);
    const accept = () => {
        deleteOnClick();
    };

    const reject = () => {};
    const deleteCat = () => {
        confirmDialog({
            message: "Do you want to delete this record?",
            header: "Delete Confirmation",
            icon: "pi pi-info-circle",
            acceptClassName: "p-button-danger",
            accept,
            reject,
        });
    };
    return (
        <div>
            <div>
                <div className="flex flex-row justify-content-between">
                    <h4>{cat?.category?.name}</h4>

                    {/* <ConfirmDialog /> */}
                    <Toast ref={toast} />
                    <div className="flex flex-row" style={{alignItems:"center"}}>
                        <Button
                            icon="pi pi-pencil"
                            className="p-button-rounded p-button-text"
                            aria-label="Submit"
                            onClick={() => {
                                categoryOnClick();
                                onSelectedCategory();
                            }}
                        />
                        <Button
                            icon="pi pi-trash"
                            className="p-button-rounded p-button-text"
                            aria-label="Submit"
                            style={{ color: "red" }}
                            onClick={deleteCat}
                            // onClick={() => deleteOnClick()}
                        />

                        <i
                            onClick={() => {
                                setExpanded(!expanded);
                            }}
                            className={`${expanded ? "pi pi-chevron-up" : "pi pi-chevron-down"}`}
                        ></i>
                    </div>
                </div>
            </div>
            {expanded && (
                <div className="">
                    <div className="">
                        {list
                            // .filter((item) => item.category?._id === cat._id)
                            .map((item) => {
                                return (
                                    <div key={item._id}>
                                        <TutorialItem className="card" tutorial={item} video={item.videoLink} title={item.title} />
                                    </div>
                                );
                            })}
                    </div>
                </div>
            )}
        </div>
    );
}
