import { Button } from "primereact/button";
import React, { useEffect } from "react";
import CustomInputField from "../../../components/custom_input_filed";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { addVehicleModels, resetAddEditVehicleModels, updateVehicleModels } from "../../../../../redux/slices/vehicle_models_slice";
import { getVehicleMakers } from "../../../../../redux/slices/vehicle_makers_slice";
import { getVehicleType } from "../../../../../redux/slices/vehicle_type_slice";
import { toast } from "react-toastify";
import { getVehicleModelById } from "../../../../../redux/slices/vehicle_model_by_id_slice";

const VModelDialoge = ({ vehicleModelList,onHide, editUser, modelId, permissions }) => {
    const dispatch = useDispatch();

    //redux
    const { data: vehicleTypeList } = useSelector((state) => state.vehicleType);
    const { data: vehicleMakersList } = useSelector((state) => state.vehicleMakers);
    const { addLoading, addSuccess, addError, updateSuccess, updateError, updateLoading } = useSelector((state) => state.vehicleModel);
    const { data: listById } = useSelector((state) => state.vehicleModelById);
    //hooks
    // const [vehicleType, setVehicleType] = useState("");
    // const [vehicleMakers, setVehicleMakers] = useState("");

    const validationSchema = Yup.object().shape({
        type: Yup.string().required("Vehicle type field is required."),
        make: Yup.string().required("Vehicle maker field is required."),
        model: Yup.string().required("Vehicle model field is required."),
        seatingCapacity: Yup.string().required("Vehicle Seating capacity is required."),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            type: "",
            make: "",
            model: "",
            seatingCapacity: "",
        },

        onSubmit: async (data) => {
            // let body = {
            //     type: vehicleType,
            //     make: vehicleMakers,
            //     model: data.model,
            //     seatingCapacity: data.seatingCapacity,
            // };

            if (editUser == null) {
                dispatch(addVehicleModels(data));
            } else {
                data["modelId"] = editUser._id;
                // //console.log(data);
                dispatch(updateVehicleModels(data));
            }
        },
    });

    useEffect(() => {
        if (addSuccess !== undefined) {
            if (addSuccess === true) {
                toast.success("Vehicle Model successfully added");
                onHide();
                // dispatch(vehicleModelList());
            } else {
                toast.error(addError);
            }
        }

        if (updateSuccess !== undefined) {
            if (updateSuccess === true) {
                toast.success("Vehicle Model successfully updated");
                onHide();
                // dispatch(vehicleModelList());
            } else {
                toast.error(updateError);
            }
        }

        return () => {
            dispatch(resetAddEditVehicleModels());
            dispatch(
                vehicleModelList({
                    pageNumber: 0,
                    vehicleType: "",
                    vehicleMake: "",
                    status: "",
                    text: "", // Assuming 'text' is used for global search; set to empty to clear.
                })
            );
        };
    }, [addSuccess, addError, updateSuccess, updateError]);

    //vehicle type and make dropdown list
    // useEffect(() => {
    //     if (vehicleTypeList === undefined) {
    //         dispatch(getVehicleType(vehicleType));
    //     }
    // }, [vehicleType,dispatch]);

    useEffect(() => {
        if (vehicleTypeList === undefined) {
            dispatch(getVehicleType());
        }
    }, [vehicleTypeList, dispatch]);

    useEffect(() => {
        if (vehicleMakersList === undefined) {
            dispatch(getVehicleMakers());
        }
    }, [vehicleMakersList, dispatch]);

    // get vehicle model by id
    useEffect(() => {
        dispatch(getVehicleModelById({ modelId }));
    }, []);

    //getting the value of type and make by getModelById and displaying in update dialoge box
    useEffect(() => {
        if (listById !== undefined) {
            formik.setFieldValue("type", listById.type);
        }
        // //console.log(listById)
    }, [listById]);

    useEffect(() => {
        if (listById !== undefined) {
            formik.setFieldValue("make", listById.make);
        }
        // //console.log(listById)
    }, [listById]);

    useEffect(() => {
        if (editUser != null) {
            loadInitialValues();
        }
    }, [editUser]);

    const loadInitialValues = () => {
        formik.setFieldValue("model", editUser.model);
        formik.setFieldValue("seatingCapacity", editUser.seatingCapacity);
    };

    return (
        <div>
            <form onSubmit={formik.handleSubmit}>
                <div className="grid">
                    <div className="col-12">
                        <div className="p-fluid grid">
                            <div className="col-12 md:col-6">
                                <div className="field">
                                    {/* <Dropdown className="text-left" name="type" options={vehicleTypeList} optionLabel="type" optionValue="_id" value={vehicleType} onChange={(e) => setVehicleType(e.value)} placeholder="Select a Vehicle Type " formik={formik} />{" "} */}
                                    <CustomInputField type="dropdown" iden="type" label="Vehicle Type" name="type" options={vehicleTypeList} formik={formik} optionLabel="type" optionValue="_id" placeHolder="Please Select" />
                                </div>
                            </div>
                            <div className="col-12 md:col-6">
                                <div className="field">
                                    {/* <Dropdown className="text-left" name="make" options={vehicleMakersList} optionLabel="make" optionValue="_id" value={vehicleMakers} onChange={(e) => setVehicleMakers(e.value)} placeholder="Select Vehicle Makers" formik={formik} /> */}
                                    <CustomInputField type="dropdown" iden="make" label="Vehicle Makers" name="make" options={vehicleMakersList} formik={formik} optionLabel="make" optionValue="_id" placeHolder="Please Select" />
                                </div>
                            </div>
                            <div className="col-12 md:col-6">
                                <div className="field">
                                    <CustomInputField label="Model" iden={"model"} formik={formik} type="text" />
                                </div>
                            </div>
                            <div className="col-12 md:col-6">
                                <div className="field">
                                    <CustomInputField label="Seating Capacity" iden={"seatingCapacity"} formik={formik} type="text" />
                                </div>
                            </div>
                            <div className="col-12 md:col-12">
                                <div className="button_class-modal">{permissions?.add && editUser == null && <Button loading={editUser == null ? addLoading : updateLoading} type="submit" label={editUser === null ? "Add" : "Update"} className="p-button-success btn__default_modal" />} </div>
                                <div className="button_class-modal">{permissions?.edit && editUser && <Button loading={editUser == null ? addLoading : updateLoading} type="submit" label={editUser === null ? "Add" : "Update"} className="p-button-success btn__default_modal" />} </div>
                                {/* <div className="button_class-modal"> <Button loading={editUser == null ? addLoading : updateLoading} type="submit" label={editUser === null ? "Add" : "Update"} className="p-button-success btn__default_modal" /> </div> */}

                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default VModelDialoge;
