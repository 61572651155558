import React, { useContext, useEffect, useState } from "react";
import { Button } from "primereact/button";
import TicketComponent from "./ticket_component";
import { getSmsLogs } from "../../../../../redux/slices/sms_slice_log";
import { useDispatch } from "react-redux";
import CustomLoading from "../../../components/custom_loading";
import { useParams } from "react-router-dom";
import encryptData from "../../../services/encryptData";
import decryptData from "../../../services/decryptData";
import { InputText } from "primereact/inputtext";
import { SocketContext } from "../../../../../Context";
import { toast } from "react-toastify";

export default function ResolvedSupportRequestsScreen({ status, permissions }) {
    const [tickets, setTickets] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(0);
    const [loadingMore, setLoadingMore] = useState(false);
    const [startDate, setStartDate] = useState(new Date().getFullYear().toString() + "-" + new Date().getMonth().toString().padStart(2, 0) + "-" + new Date().getDate().toString().padStart(2, 0));

    const [endDate, setEndDate] = useState(new Date().getFullYear().toString() + "-" + (new Date().getMonth() + 1).toString().padStart(2, 0) + "-" + new Date().getDate().toString().padStart(2, 0));

    const params = useParams();
    const { type } = params;
    const { socket } = useContext(SocketContext);
    const dispatch = useDispatch();


    useEffect(() => {
        if (page == 0) setLoading(true);
        setTimeout(() => {
            // socket.emit("allTickets", JSON.stringify(encryptData({ page, flag: "open", startDate, endDate })), (data) => {
            //     setLoading(false);
            //     setLoadingMore(false);
            //     data = decryptData(data?.cipher);
            //     const filteredTickets = data?.data?.filter((item) => !item?.hasOwnProperty("corporate"));
            //     setTickets((tickets) => [...tickets, ...filteredTickets]);
            // });

            socket.emit("allTickets", JSON.stringify(encryptData({ page, flag: "open", startDate, endDate })), (data) => {
                setLoading(false);
                setLoadingMore(false);
                data = decryptData(data?.cipher);
                const filteredTickets = data?.data?.filter((item) => !item?.hasOwnProperty("corporate")) || [];

                // Reset or append tickets
                setTickets((tickets) => (page === 0 ? filteredTickets : [...tickets, ...filteredTickets]));

                // Toast message for no data
                if (page === 0 && filteredTickets.length === 0) {
                    toast.info("No tickets found for the selected date range.");
                }
            });

            socket.on("newTicket", (parseData) => {
                setLoading(false);
                setLoadingMore(false);
                let data = JSON.parse(parseData);
                data = decryptData(data?.cipher);
                if (!data?.corporate) {
                    toast.info("New Ticket Generated!");
                }
            });

            return () => {
                socket.off("newTicket");
            };
        }, 3000);
    }, [page, endDate, startDate]);

    const handleLoadMore = () => {
        setLoadingMore(true);
        setPage((page) => page + 1);
    };

    useEffect(() => {
        dispatch(getSmsLogs({ startDate, endDate }));
    }, [endDate, startDate, dispatch]);
    return (
        <>
            {loading ? (
                <CustomLoading />
            ) : (
                <>
                    <div className="grid">
                        <div className="col-12 md:col-7">{/* <CustomHeader title={"Reports"} /> */}</div>
                        <div className="col-12 md:col-5 text-right">
                            <div className="both__date">
                                {/* <InputText value={startDate} onChange={(e) => setStartDate(e.target.value)} type="date" className="date__input" />

                                <InputText value={endDate} onChange={(e) => setEndDate(e.target.value)} type="date" className="date__input" /> */}
                                <InputText
                                    value={startDate}
                                    onChange={(e) => {
                                        const selectedDate = e.target.value;
                                        if (new Date(selectedDate) > new Date(endDate)) {
                                            toast.error("Start date cannot be greater than End date");
                                        } else {
                                            setStartDate(selectedDate);
                                        }
                                    }}
                                    type="date"
                                    className="date__input"
                                />

                                <InputText
                                    value={endDate}
                                    onChange={(e) => {
                                        const selectedDate = e.target.value;
                                        if (new Date(selectedDate) < new Date(startDate)) {
                                            toast.error("End date cannot be less than Start date");
                                        } else {
                                            setEndDate(selectedDate);
                                        }
                                    }}
                                    type="date"
                                    max={new Date().toISOString().split("T")[0]} // Disable future dates
                                    className="date__input"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="card">
                        <div className="flex flex-row justify-content-between align-content-center align-items-center pb-3">
                            <h5>Tickets</h5>
                        </div>
                        {tickets.length === 0 && !loading && <p>No tickets found for the selected filters.</p>}
                        {tickets?.map((item) => (
                            <TicketComponent permissions={permissions} key={item._id} ticket={item} status={status} />
                        ))}
                        {tickets?.length < (page + 1) * 10 ? null : <div className="text-center">{loadingMore ? <CustomLoading /> : <Button label="Load More" onClick={handleLoadMore} />}</div>}
                    </div>
                </>
            )}
        </>
    );
}
