import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import "primeicons/primeicons.css";
import { Dialog } from "primereact/dialog";
import CustomLoading from "../../../components/custom_loading";
import { toast } from "react-toastify";
import CustomPageHeader from "../../../components/custom_page_header";
import AddEditTutorialCategory from "./add-edit-tutorial-category";
import AddEditTutorial from "./add-edit-tutorial-dailoge";
import { useDispatch, useSelector } from "react-redux";
import { getTutorialsList, resetAddEditTutorials } from "../../../../../redux/slices/tutorials_slice";
import TutorialCategory from "../components/tutorial_category";
import { deleteTutorialsCategory, getTutorialsCategoryList, resetAddEditTutorialsCategory } from "../../../../../redux/slices/tutorials_category_slice";

export default function TutorailScreen({ permissions }) {
    const dispatch = useDispatch();
    const { data: tutorialList, deleteSuccess: deleteTutorialSuccess, deleteError: deleteTutorialError } = useSelector((state) => state.tutorialList);
    const { data: list, loading, deleteSuccess, deleteError } = useSelector((state) => state.tutorialCategoryList);
    const [editCategory, setEditCategory] = useState(null);
    const [editTutorial, setEditTutorial] = useState(null);
    const [showAddEditTutorialCategory, setAddEditTutorialCategory] = useState(false);
    const [showAddEditTutorialDialog, setShowAddEditTutorialDialog] = useState(false);
    const dialogFuncMap = {
        showAddEditTutorialCategory: setAddEditTutorialCategory,
        showAddEditTutorialDialog: setShowAddEditTutorialDialog,
    };
    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);
    };

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    };

    useEffect(() => {
        if (list === undefined) {
            dispatch(getTutorialsCategoryList());
        }
    }, []);
    useEffect(() => {
       
        if (tutorialList === undefined) {
            dispatch(getTutorialsList());
        }
    }, []);
    useEffect(() => {
        if (deleteSuccess !== undefined) {
            if (deleteSuccess === true) {
                toast.success("Category deleted");
                dispatch(getTutorialsCategoryList());
            } else {
                toast.error(deleteError);
            }
        }
        return () => {
            dispatch(resetAddEditTutorialsCategory());
        };
    }, [deleteSuccess, deleteError]);

    useEffect(() => {
        if (deleteTutorialSuccess !== undefined) {
            if (deleteTutorialSuccess === true) {
                toast.success("Tutorials deleted");
                dispatch(getTutorialsList());
            } else {
                toast.error(deleteTutorialError);
            }
        }
        return () => {
            dispatch(resetAddEditTutorials());
        };
    }, [deleteTutorialSuccess, deleteTutorialError]);

    return (
        <>
            {loading === true || list === undefined ? (

                
                <CustomLoading />
            ) : (
                <div className="accordion-demo">
                    <div className="card">
                        <Dialog header={editCategory == null ? "Add category" : "Update category"} visible={showAddEditTutorialCategory} onHide={() => onHide("showAddEditTutorialCategory")} breakpoints={{ "960px": "75vw", "640px": "95vw" }} style={{ width: "50vw" }}>
                            <AddEditTutorialCategory permissions={permissions} editData={editCategory} onHide={() => onHide("showAddEditTutorialCategory")} />
                        </Dialog>

                        <Dialog header="Add Tutorials" visible={showAddEditTutorialDialog} onHide={() => onHide("showAddEditTutorialDialog")} breakpoints={{ "960px": "75vw", "640px": "95vw" }} style={{ width: "70vw" }}>
                            <AddEditTutorial permissions={permissions} editData={editTutorial} onHide={() => onHide("showAddEditTutorialDialog")} />
                        </Dialog>

                        <div className="pb-5">
                            <div className="grid">
                                <div className="col-12 md:col-6">
                                    <CustomPageHeader title={"How It Works ?"} />
                                </div>
                                <div className="col-12 md:col-6 text-right">
                                    {permissions?.add && (
                                        <Button
                                            label="Add Category"
                                            type="submit"
                                            className="mr-3 p-button-success p-button-rounded p-button-sm custom___width mb_width"
                                            onClick={() => {
                                                setEditCategory(null);
                                                onClick("showAddEditTutorialCategory");
                                            }}
                                        />
                                    )}
                                    {permissions?.add && (
                                        <Button
                                            className="p-button-success p-button-rounded p-button-sm custom___width mb_width"
                                            label="Add Tutorial"
                                            type="submit"
                                            onClick={() => {
                                                onClick("showAddEditTutorialDialog");
                                            }}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className=" mt-5">
                            {list.map((cat, index) => {
                                return (
                                    <div key={cat._id} className="card">
                                        <div className="grid">
                                            <div className="col-12">
                                                <TutorialCategory
                                                    permissions={permissions}
                                                    // title={cat.title}
                                                    cat={cat}
                                                    // video={cat.videoLink}
                                                  
                                                    deleteOnClick={() => {
                                                        dispatch(deleteTutorialsCategory(cat));
                                                    }}
                                                    categoryOnClick={() => onClick("showAddEditTutorialCategory")}
                                                    onSelectedCategory={() => {
                                                        setEditCategory(cat);
                                                    }}
                                                />
                                               
                                            </div>
                                        </div>
                                        {/* <h1>helllo 22</h1> */}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
} 
