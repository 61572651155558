import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect } from "react";
import { formateDateTime } from "../../../services/date_service";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUserRating } from "../../../../../redux/slices/ratings_slice";
import { Rating } from "primereact/rating";

export default function DriverRatingScreen() {
    const dispatch = useDispatch();
    const { data, loading } = useSelector((state) => state.userRating);

    const param = useParams();
    const { userId, type } = param;

    //hooks
    useEffect(() => {
        let body = {
            userId: userId,
            isDriver: type === "1",
        };

        dispatch(getUserRating(body));
    }, [dispatch, type, userId]);
    return (
        <div>
            <div className="card">
                <h3>Feedbacks</h3>

                <DataTable rows={50} paginator responsiveLayout="scroll" loading={loading} value={data} key="_id" emptyMessage="No record found.">
                    <Column className="maker_colm" filter body={(data, props) => <div>{props.rowIndex + 1}</div>} header="Sr" />
                    <Column className="maker_colm" filter field="ratingBy.firstName" header="Rating by" />
                    <Column className="maker_colm" filter field="routeId.startPoint.placeName" header="Owner start point" />
                    <Column className="maker_colm" filter field="routeId.endPoint.placeName" header="Owner End point" />
                    <Column className="maker_colm" filter field="scheduleId.startPoint.placeName" header="Passenger start point" />
                    <Column className="maker_colm" filter field="scheduleId.endPoint.placeName" header="Passenger End point" />
                    <Column className="maker_colm" filter body={(data) => <div>{formateDateTime(data.date)}</div>} field="date" header="Date" />
                    <Column
                        className="maker_colm"
                        sortable
                        body={(data) => {
                            return (
                                <div>
                                    <Rating className="flex" value={data.rating} readOnly cancel={false} stars={5} />
                                </div>
                            );
                        }}
                        header="Rating"
                    />
                    <Column className="maker_colm" filter field="text" header="Message" />
                </DataTable>
            </div>
        </div>
    );
}
