import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";
// import { Toast } from 'primereact/toast';
import { toast } from "react-toastify";
import { Checkbox } from "primereact/checkbox";
//import { UPDATE_USER_STATUS_RESET } from '../../../../../redux/constants/passenger_constatns';
//import { getDriversList } from '../../../../../redux/actions/drivers_actions';
import CustomInputField from "../../../components/custom_input_filed";
import { getUsersList, resetUpdateUsers, updateUserStatus } from "../../../../../redux/slices/users_slice";

const validationSchema = Yup.object().shape({
    // comment: Yup.string().required("Comment is required"),
});

export default function EditUserDialog({ getDriversList, permissions, editUser, onHide, source, getUsersList }) {
    //redux
    const editUserReducer = useSelector((state) => state.usersList);
    const { loading, error, success, updateSuccess, updateError } = editUserReducer;
    const getUsersListReducer = useSelector((state) => state.usersList);
    const { users, count } = getUsersListReducer;
    const [isDriversListSource, setIsDriversListSource] = useState(source === "drivers");

    //hooks
    const dispatch = useDispatch();
    console.log(editUserReducer, "editUserReducer");
    console.log(success, "success");
    console.log(updateSuccess, "updateSuccess");

    // const toast = useRef(null);
    const loadEditData = () => {
        if (editUser && editUser.active) {
            formik.setFieldValue("status", editUser.active.status);
        }
    };

    useEffect(() => {
        loadEditData();
    }, []);

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            status: false,
            comment: "",
        },
        onSubmit: async (data) => {
            data["userId"] = editUser._id;
            if (editUser != null) {
                dispatch(updateUserStatus(data));

                if (isDriversListSource) {
                    dispatch(
                        getDriversList({
                            userType: 2,
                            pageNumber: 0,
                            text: "",
                            perPageRecords: 50,
                            gender: "",
                            status: "",
                            loginDevice: "",
                            corporate: editUser?.corporate,
                        })
                    );
                } else {
                    dispatch(
                        getUsersList({
                            userType: 1,
                            pageNumber: 0,
                            text: "",
                            perPageRecords: 50,
                            gender: "",
                            status: "",
                            loginDevice: "",
                        })
                    );
                }
                toast.success("Status successfully updated");
                onHide();
            }
        },
    });
    // useEffect(() => {
    //     if (success !== undefined) {
    //         if (success === true) {
    //             formik.resetForm();
    //             onHide();
    //             //dispatch(getDriversList());
    //         }
    //     }

    //     if (updateSuccess !== undefined) {
    //         if (updateSuccess === true) {
    //             toast.success("Status successfully updated");
    //             onHide();
    //             // window.location.reload();

    //         } else {
    //             toast.error(updateError);
    //         }
    //     }
    //     return () => {
    //         dispatch(resetUpdateUsers());
    //     };
    //     // return () => {
    //     //     dispatch({ type: UPDATE_USER_STATUS_RESET });
    //     // }
    // }, [updateSuccess,updateError]);
    return (
        <>
            {/* <Toast ref={toast} /> */}
            <form onSubmit={formik.handleSubmit}>
                {
                    <div className="grid">
                        <div className="col-12">
                            {/* <h5>
                                {editUser?.firstName?.charAt(0).toUpperCase() + editUser?.firstName?.slice(1)} {editUser?.lastName?.charAt(0).toUpperCase() + editUser?.lastName?.slice(1)}
                            </h5> */}
                            <div className="p-fluid grid">
                                {/* <div className='grid'> */}
                                <div className="col-12">
                                    <div className="field-checkbox">
                                        <Checkbox inputId="status" name="status" checked={formik.values.status} onChange={formik.handleChange} />
                                        <label htmlFor="status">Active status</label>
                                    </div>
                                </div>
                                {!formik.values.status && (
                                    <div className="col-12">
                                        <div className="">
                                            <CustomInputField iden={"comment"} formik={formik} type="textarea" />
                                        </div>
                                    </div>
                                )}
                                {/* </div> */}

                                <div className="col-12">
                                    <div className="button_class-modal"> {permissions?.edit && <Button loading={loading} type="submit" label={editUser === null ? "Add" : "Update"} className="p-button-success btn__default_modal p-button-rounded" />}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </form>
        </>
    );
}
