import React, { useRef } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "./redux/slices/admin_user_slice";
import appUrl from "./app/features/constants/appUrl";
import { Image } from "primereact/image";
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import { OverlayPanel } from "primereact/overlaypanel";
//import {  LOGOUT_USER } from './redux/constants/admin_user_constants';
import { useHistory } from "react-router-dom";
export const AppTopbar = ({ onToggleMenuClick, layoutColorMode, mobileTopbarMenuActive, onMobileTopbarMenuClick, onMobileSubTopbarMenuClick, vehicleCount, unverifiedDriversCount }) => {
    const history = useHistory();
    let user = localStorage.getItem("user");
    if (user) {
        user = JSON.parse(user);
    }
    // console.log(user);
    const dispatch = useDispatch();
    const accept = () => {
        //dispatch({type:LOGOUT_USER})
        dispatch(logoutUser());
    };
    const op = useRef(null);
    const reject = () => {};
    const totalNotifications = vehicleCount + unverifiedDriversCount;

    const signoutConfirm = () => {
        confirmDialog({
            message: "Do you want to logout?",
            header: "Confirmation",
            icon: "pi pi-info-circle",
            acceptClassName: "p-button-success p-button-rounded p-button-sm custom___width",
            accept,
            reject,
        });
    };

    const menuRight = useRef(null);
    const items = [
        {
            label: "Logout",
            items: [
                {
                    label: "Profile",
                    icon: "pi pi-user",
                    command: () => {
                        history.push("/api/profile");
                    },
                },
                {
                    label: "Sign out",
                    icon: "pi pi-sign-out",
                    command: () => {
                        signoutConfirm();
                    },
                },
            ],
        },
    ];
    return (
        <div className="layout-topbar">
            <ConfirmDialog />

            <Link to="/" className="layout-topbar-logo">
                <img src="app/logo.png" alt="logo" />
                {/* <span>Rahper</span> */}
            </Link>

            <button type="button" className="p-link  layout-menu-button layout-topbar-button" onClick={onToggleMenuClick}>
                <i className="pi pi-bars" />
            </button>

            <button type="button" className="p-link layout-topbar-menu-button layout-topbar-button" onClick={onMobileTopbarMenuClick}>
                <i className="pi pi-ellipsis-v" />
            </button>

            <ul className={classNames("layout-topbar-menu lg:flex origin-top", { "layout-topbar-menu-mobile-active": mobileTopbarMenuActive })}>
                <li style={{ display: "flex", marginRight: "15px", alignItems: "center", padding: "0.65625rem 1.09375rem", background: "#5052E2", borderRadius: "100px" }}>
                    {user?.role.role && <span style={{ marginLeft: "0px", fontWeight: "semi-bold", fontSize: "13px", color: "#fff" }}>{user?.role.role}</span>}
                </li>
                <li style={{ display: "flex", marginRight: "15px", alignItems: "center" }}>
                    <div style={{ position: "relative" }}>
                        <i className="pi pi-bell" style={{ cursor: "pointer", fontSize: "1.5rem" }} onClick={(e) => op.current.toggle(e)}></i>
                        {totalNotifications > 0 && (
                            <span
                                style={{
                                    position: "absolute",
                                    top: "-10px",
                                    right: "-12px",
                                    background: "red",
                                    color: "white",
                                    borderRadius: "50%",
                                    padding: "2px 6px",
                                    fontSize: "0.8rem",
                                }}
                            >
                                {totalNotifications}
                            </span>
                        )}
                    </div>
                    <OverlayPanel ref={op}>
                        <h1 style={{ marginBottom: "10px", fontSize: "1.2rem" }}>Notifications</h1>

                        {/* Check if there are no vehicles or drivers
                        {vehicleCount === 0 && unverifiedDriversCount === 0 && <p style={{ color: "gray" }}>There are no pending vehicles or drivers.</p>} */}

                        {/* Vehicle notification */}
                        {vehicleCount > 0 && (
                            <p style={{ color: "#007BFF" }}>
                                <Link to="/api/verification/vehicles" style={{ color: "#007BFF" }}>
                                    You have {vehicleCount} {vehicleCount === 1 ? "Pending Vehicle" : "Pending Vehicles"}.
                                </Link>
                            </p>
                        )}

                        {vehicleCount > 0 && unverifiedDriversCount > 0 && <hr style={{ margin: "10px 0" }} />}

                        {/* Driver notification */}
                        {unverifiedDriversCount > 0 && (
                            <p style={{ color: "#007BFF" }}>
                                <Link to="/api/verification/personal" style={{ color: "#007BFF" }}>
                                    You have {unverifiedDriversCount} {unverifiedDriversCount === 1 ? "Unverified Driver" : "Unverified Drivers"}.
                                </Link>
                            </p>
                        )}
                    </OverlayPanel>
                </li>
                <li style={{ display: "flex", alignItems: "center" }}>
                    <Link to="/api/profile">
                        <Image imageStyle={{ borderColor: "red", borderRadius: "50%", width: "30px", height: "30px" }} src={user?.profileImage ? appUrl.baseUrl + user.profileImage : appUrl.baseUrl + "images/defaultProfile.jpg"} />
                    </Link>
                    {user?.userName && <span style={{ marginLeft: "10px", fontWeight: "bold" }}>{user.userName}</span>}
                </li>
                {/* Additional menu items can go here */}
            </ul>
            <div className="pms-topbar">
                <div className="flex align-items-center">
                    <Menu model={items} popup ref={menuRight} id="popup_menu_right" popupAlignment="right" />
                    <button className="p-link layout-topbar-button mx-4" onClick={(e) => menuRight.current.toggle(e)}>
                        <i className="pi pi-power-off"></i>
                    </button>
                </div>
            </div>
        </div>
    );
};
