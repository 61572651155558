import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useDispatch, useSelector } from "react-redux";
import AddEditRole from "../components/add_edit_roles";
import { Dialog } from "primereact/dialog";
import { getModulesList } from "../../../../../redux/slices/modules_slice";
import { getUserRoles } from "../../../../../redux/slices/admin_user_slice";
import AddEditRolePermission from "../components/add_edit_role_permissions";
import { roleModules } from "../../../../../redux/slices/roles_slice";
export default function Roles({ permissions }) {
    //redux
    const getUserReducer = useSelector((state) => state.getUser);
    const { roles, editSuccess } = getUserReducer;
    // console.log(roles,"roles")
    const rolesState = useSelector((state) => state.rolesList);
    const { modulesList = [] } = rolesState;
    // console.log(roles,"roles")
    const [expandedRows, setExpandedRows] = useState(null);
    const dispatch = useDispatch();
    const [refreshList, setRefreshList] = useState(false);
    const [editCategory, setEditCategory] = useState(null);
    const [showAddEditCategoryDialog, setAddEditCategoryDialog] = useState(false);
    const [showAddEditSubCategoryDialog, setAddEditSubCategoryDialog] = useState(false);
    const [expandedRole, setExpandedRole] = useState(null);

    const dialogFuncMap = {
        showAddEditCategoryDialog: setAddEditCategoryDialog,
        showAddEditSubCategoryDialog: setAddEditSubCategoryDialog,
    };

    const onClick = (name) => {
        dialogFuncMap[`${name}`](true);
    };

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    };

    useEffect(() => {
        dispatch(getModulesList());
    }, [editSuccess]);
    const actionTemplate = (rowData) => {
        return (
            <div className="Edit_Icon">
                {permissions?.edit && (
                    <Button
                        tooltip="Update Permission"
                        icon="pi pi-pencil"
                        tooltipOptions={{ position: "top" }}
                        onClick={() => {
                            setEditCategory(rowData);
                            onClick("showAddEditCategoryDialog");
                        }}
                        className="edit mr-2 p-button-success p-button-rounded"
                    />
                )}
            </div>
        );
    };

    const subActionTemplate = (rowData) => {
        return (
            <div className="Edit_Icon">
                {permissions?.add && (
                    <Button
                        tooltip="Add Edit Permission"
                        icon="pi pi-pencil"
                        tooltipOptions={{ position: "top" }}
                        onClick={() => {
                            setEditCategory(rowData);
                            onClick("showAddEditSubCategoryDialog");
                        }}
                        className="edit mr-2 p-button-success p-button-rounded"
                    />
                )}
            </div>
        );
    };
    const allowExpansion = (rowData) => {
        return <>{rowData.length > 0}</>;
    };
    const expandedTableTemp = (rowData) => {
        setExpandedRole(rowData._id);
        const updatedModules = modulesList?.flatMap((item) =>
            item?.items?.map((nestedItem) => {
                return { moduleName: item.label, subModuleName: nestedItem.label, subModuleId: nestedItem._id };
            })
        );
        return (
            <DataTable showGridlines={true} value={updatedModules ? updatedModules : []}>
                <Column className="maker_colm" style={{ minWidth: "22rem" }} field="moduleName" header="Module" />
                <Column className="maker_colm" style={{ minWidth: "22rem" }} field="subModuleName" header="Sub Module" />
                <Column className="maker_colm" body={subActionTemplate} header="Action" />
            </DataTable>
        );
    };
    useEffect(() => {
        if (expandedRole) dispatch(roleModules(expandedRole));
    }, [expandedRole]);

    useEffect(() => {
        if (roles === undefined || roles.length === 0 || editSuccess) {
            dispatch(getUserRoles());
        }
    }, []);

    useEffect(() => {
        if (refreshList) {
            setRefreshList(false);
            dispatch(roleModules(expandedRole));
        }
    }, [refreshList]);

    return (
        <>
            <Dialog header={editCategory == null ? "Add Role" : "Update Role"} visible={showAddEditCategoryDialog} onHide={() => onHide("showAddEditCategoryDialog")} breakpoints={{ "960px": "75vw", "640px": "95vw" }} style={{ width: "50vw" }}>
                <AddEditRole setRefreshList={setRefreshList} permissions={permissions} editData={editCategory} onHide={() => onHide("showAddEditCategoryDialog")} />
            </Dialog>
            <Dialog header={editCategory == null ? "Add Role Permissions" : "Update Role Permissions"} visible={showAddEditSubCategoryDialog} onHide={() => onHide("showAddEditSubCategoryDialog")} breakpoints={{ "960px": "75vw", "640px": "95vw" }} style={{ width: "30vw" }}>
                <AddEditRolePermission permissions={permissions} editData={editCategory} roleId={expandedRole} onHide={() => onHide("showAddEditSubCategoryDialog")} />
            </Dialog>

            <div className="card">
                <div className="grid pb-3">
                    <div className="col-12 md:col-6">
                        <h4>Roles Management System</h4>
                    </div>
                    <div className="col-12 md:col-6">
                        <div className="mb-9 text-right">
                            {permissions?.add && (
                                <Button
                                    label="Add Role"
                                    type="submit"
                                    className="mr-3 p-button-success p-button-rounded p-button-sm custom___width mb_width"
                                    onClick={() => {
                                        setEditCategory(null);
                                        onClick("showAddEditCategoryDialog");
                                    }}
                                />
                            )}
                        </div>
                    </div>
                </div>
                <DataTable
                    filter
                    rows={50}
                    paginator
                    // value={roleList}
                    value={roles}
                    responsiveLayout="scroll"
                    emptyMessage="No record found."
                    expandedRows={expandedRows}
                    rowExpansionTemplate={expandedTableTemp}
                    onRowToggle={(e) => {
                        setExpandedRows(e.data);
                    }}
                >
                    <Column className="maker_colm" expander={allowExpansion} style={{ width: "3em" }} />
                    <Column className="maker_colm" style={{ minWidth: "12rem", padding: "0.75rem" }} field="role" header="Roles"/>
                    <Column className="maker_colm" style={{ minWidth: "12rem", padding: "0.75rem" }} body={actionTemplate} header="Action"/>
                </DataTable>
            </div>
        </>
    );
}
