import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosApi } from "../../app/features/constants/axios_api";
import appUrl from "../../app/features/constants/appUrl";

// Thunks

export const addNewCorporate = createAsyncThunk("corporate/add", async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosApi.post(appUrl.baseUrl + appUrl.addNewCorporate, body);
        return fulfillWithValue(data.msg);
    } catch (error) {
        return rejectWithValue(error.response && error.response.data.msg ? error.response.data.msg : error.message);
    }
});


const corporateAddReducer = createSlice({
    name: "corporate",
    initialState: {},
    reducers: {
        addEditCorporate(state, action) {
            return { ...state, addSuccess: undefined, editSuccess: undefined, addError: undefined, editError: undefined };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(addNewCorporate.pending, (state, action) => {
                return { ...state, loading: true };
            })
            .addCase(addNewCorporate.fulfilled, (state, action) => {
                return { ...state, loading: false, data: action.payload, count: action.payload.count,addSuccess:true };
            })
            .addCase(addNewCorporate.rejected, (state, action) => {
                return {
                    ...state,
                    loading: false,
                    error: action.payload,
                };
            });
          builder
            .addCase(getGoogleAddress.pending, (state, action) => {
                return { ...state, loading: true };
            })
            .addCase(getGoogleAddress.fulfilled, (state, action) => {
                return { ...state, loading: false, address: action.payload.data };
            })
            .addCase(getGoogleAddress.rejected, (state, action) => {
                return {
                    ...state,
                    loading: false,
                    error: action.payload,
                };
            });
    },
});

export const { addEditCorporate } = corporateAddReducer.actions;
export default corporateAddReducer.reducer;

export const getGoogleAddress = createAsyncThunk("googleAddress/fetch", async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosApi.get(appUrl.baseUrl + appUrl.getGoogleAddress + `?text=${body.text}`);

        return fulfillWithValue({ data: data.data });
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.msg ? error.response.data.msg : error.message);
    }
});