import Axios from "axios";
import appUrl from "../../app/features/constants/appUrl";
import { axiosApi } from "../../app/features/constants/axios_api";
import { toast } from "react-toastify";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

const adminUserSlice = createSlice({
    name: "adminUserSlice",
    initialState: {},
    reducers: {
        resetAdminUser(state, action) {
            return { ...state, resetPassword: undefined, loginSuccess: undefined, addSuccess: undefined, editSuccess: undefined, deleteSuccess: undefined };
        },
        logoutUser(state, action) {
            localStorage.removeItem("user");
            return {};
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginAdminUser.pending, (state, action) => {
                return { ...state, loading: true };
            })
            .addCase(loginAdminUser.fulfilled, (state, action) => {
                return { ...state, loading: false, user: action.payload, loginSuccess: true };
            })
            .addCase(loginAdminUser.rejected, (state, action) => {
                return {
                    ...state,
                    loading: false,
                    loginError: action.payload,
                    loginSuccess: false,
                };
            });
        builder
            .addCase(resetPasswordAdminUser.pending, (state, action) => {
                return { ...state, loading: true, resetPassword: undefined };
            })
            .addCase(resetPasswordAdminUser.fulfilled, (state, action) => {
                return { ...state, loading: false, data: action.payload, resetPassword: true };
            })
            .addCase(resetPasswordAdminUser.rejected, (state, action) => {
                return {
                    ...state,
                    loading: false,
                    loginError: action.payload,
                    resetPassword: false,
                };
            });
        builder
            .addCase(getAdminUsers.pending, (state, action) => {
                return { ...state, loading: true, addSuccess: false, editSuccess: false };
            })
            .addCase(getAdminUsers.fulfilled, (state, action) => {
                return { ...state, loading: false, adminUsers: action.payload.data, count: action.payload.count, getListSuccess: true };
            })
            .addCase(getAdminUsers.rejected, (state, action) => {
                return {
                    ...state,
                    loading: false,
                    loginE: action.payload,
                };
            });
        builder
            .addCase(getAdminUserById.pending, (state, action) => {
                return { ...state, loading: true, addSuccess: false, editSuccess: false };
            })
            .addCase(getAdminUserById.fulfilled, (state, action) => {
                return { ...state, loading: false, adminUser: action.payload.data };
            })
            .addCase(getAdminUserById.rejected, (state, action) => {
                return {
                    ...state,
                    loading: false,
                    adminUser: null,
                    loginE: action.payload,
                };
            });
        builder
            .addCase(updateProfileImage.pending, (state, action) => {
                return { ...state, loading: true, addSuccess: false, editSuccess: false };
            })
            .addCase(updateProfileImage.fulfilled, (state, action) => {
                return { ...state, loading: false, adminUser: action.payload.data };
            })
            .addCase(updateProfileImage.rejected, (state, action) => {
                return {
                    ...state,
                    loading: false,
                    adminUser: null,
                    loginE: action.payload,
                };
            });

        builder
            .addCase(getUserRoles.pending, (state, action) => {
                return { ...state, loading: true };
            })
            .addCase(getUserRoles.fulfilled, (state, action) => {
                return { ...state, loading: false, roles: action.payload, addedSuccess: true };
            })
            .addCase(getUserRoles.rejected, (state, action) => {
                return {
                    ...state,
                    loading: false,
                    loginE: action.payload,
                };
            });
        builder
            .addCase(addAdminUser.pending, (state, action) => {
                return { ...state, addLoading: true, addSuccess: false, addError: null };
            })
            .addCase(addAdminUser.fulfilled, (state, action) => {
                return { ...state, addLoading: false, addUser: action.payload, addSuccess: true };
            })
            .addCase(addAdminUser.rejected, (state, action) => {
                return {
                    ...state,
                    addLoading: false,
                    addError: action.payload,
                    addSuccess: false,
                };
            });
        builder
            .addCase(editAdminUser.pending, (state, action) => {
                return { ...state, editLoading: true, editSuccess: false };
            })
            .addCase(editAdminUser.fulfilled, (state, action) => {
                return { ...state, editLoading: false, editUser: action.payload, editSuccess: true };
            })
            .addCase(editAdminUser.rejected, (state, action) => {
                return {
                    ...state,
                    editLoading: false,
                    editError: action.payload,
                    editSuccess: false,
                };
            });
    },
});

export default adminUserSlice.reducer;
export const { resetAdminUser, logoutUser } = adminUserSlice.actions;

// Thunks
export const loginAdminUser = createAsyncThunk("loginAdminUser/fetch", async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosApi.post(appUrl.baseUrl + appUrl.adminLogin, body);
        //corporate User
        if (!data?.data?.corporate?._id) {
            console.log("admin");
            localStorage.setItem("user", JSON.stringify(data.data));
            localStorage.setItem("accessToken", data.token);
            localStorage.setItem("refreshToken", data.refreshToken);
            window.location.href = `#${data.data.menu[0].items[0].to}`;
            return fulfillWithValue(data.data);
        }

        //admin user
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.msg ? error.response.data.msg : error.message);
    }
});
export const resetPasswordAdminUser = createAsyncThunk("resetPasswordAdminUser/fetch", async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosApi.post(appUrl.baseUrl + appUrl.resetAdminUserPassword, body);
        return fulfillWithValue(data.data);
    } catch (error) {
        localStorage.setItem("resetError", error.response.data.msg);
        throw rejectWithValue(error.response.data.msg ? error.response.data.msg : error.message);
    }
});
export const getAdminUsers = createAsyncThunk("getAdminUsers/fetch", async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        if (body?.corporate) {
             const { data } = await axiosApi.get(appUrl.baseUrl + appUrl.getAdminUsersCorporate + `?pageNumber=${body.pageNumber}&text=${body.search}&perPageRecords=${body.perPageRecords}&role=${body.role && body.role}&status=${body.status && body.status}&corporate=${body?.corporate}`);
        return fulfillWithValue({ data: data.users, count: data.count });
        } else {
             const { data } = await axiosApi.get(appUrl.baseUrl + appUrl.getAdminUsers + `?pageNumber=${body.pageNumber}&text=${body.search}&perPageRecords=${body.perPageRecords}&role=${body.role && body.role}&status=${body.status && body.status}`);
        return fulfillWithValue({ data: data.users, count: data.count });
        }
       
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.msg ? error.response.data.msg : error.message);
    }
});

export const getAdminUserById = createAsyncThunk("getAdminUserById/fetch", async (id, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosApi.get(appUrl.baseUrl + appUrl.getAdminUserById + `?id=${id}`);
        // console.log("user",data)
        return fulfillWithValue({ data: data.data });
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.msg ? error.response.data.msg : error.message);
    }
});

export const updateProfileImage = createAsyncThunk("updateProfileImage/edit", async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosApi.patch(appUrl.baseUrl + appUrl.updateProfileImage, body);
        // console.log("user",data)
        return fulfillWithValue({ data: data.data });
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.msg ? error.response.data.msg : error.message);
    }
});

export const getUserRoles = createAsyncThunk("getUserRoles/fetch", async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosApi.get(appUrl.baseUrl + appUrl.getAllRoles);
        return fulfillWithValue(data.data);
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.msg ? error.response.data.msg : error.message);
    }
});

export const addAdminUser = createAsyncThunk("addAdminUser/add", async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosApi.post(appUrl.baseUrl + appUrl.addAdminUser, body);
        return fulfillWithValue(data.data);
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.msg ? error.response.data.msg : error.message);
    }
});

export const editAdminUser = createAsyncThunk("updateAdminUser/edit", async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosApi.patch(appUrl.baseUrl + appUrl.editAdminUser, body);
        return fulfillWithValue(data.data);
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.msg ? error.response.data.msg : error.message);
    }
});
