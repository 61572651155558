import React, { useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import CustomInputField from "../../../components/custom_input_filed";
import { Button } from "primereact/button";
import { useDispatch, useSelector } from "react-redux";
import { activeDeActiveModule } from "../../../../../redux/slices/modules_slice";

export default function StatusChange({ onHide, editData, permissions }) {
    const dispatch = useDispatch();
    const modulesListReducer = useSelector((state) => state.modulesList);
    const { activeSuccess } = modulesListReducer;
    const validationSchema = Yup.object().shape({
        active: Yup.string().required("active is Required"),
    });
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            active: "",
        },
        onSubmit: async (data) => {
            const body = {
                moduleId: editData._id,
                active: data.active,
            };
            dispatch(activeDeActiveModule(body));
        },
    });
    const statusValue = [
        { label: "Active", value: true },
        { label: "Inactive", value: false },
    ];
    // Dynamically update form values when `editData` is available
    useEffect(() => {
        if (editData) {
            formik.setFieldValue("active", editData.active);
        }
    }, [editData]);

    useEffect(() => {
        if (activeSuccess) {
            onHide();
        }
    }, [activeSuccess]);

    return (
        <>
            <div>
                <form onSubmit={formik.handleSubmit}>
                    <div className="col-12">
                        <div className="grid p-fluid ">
                            <CustomInputField className="col-12 md:col-12" type="dropdown" iden="active" label="Status*" formik={formik} optionLabel="label" optionValue="value" options={statusValue} placeHolder="Please Select" />
                        </div>
                        <div className="flex flex-row justify-content-end">
                            <div>
                                <Button
                                    label="Cancel"
                                    onClick={() => {
                                        onHide();
                                    }}
                                    className="p-button-outlined mr-2"
                                />
                                {permissions?.edit && <Button label="Update" type="submit" className="p-button" />}
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
}
