import React, { useContext, useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
// import { SocketContext } from "../../../../../SocketProvider";
import socketsPoint from "../../../constants/socketsPoints";
import { useParams } from "react-router-dom";
import CustomLoading from "../../../components/custom_loading";
import { Image } from "primereact/image";
import appUrl from "../../../constants/appUrl";
import { formatDate } from "@fullcalendar/core";
import ChatBubble from "../components/chat_bubble";
import { InputText } from "primereact/inputtext";
import { useSelector } from "react-redux";
import { fileServices } from "../../../services/file_services";
import CustomInputField from "../../../components/custom_input_filed";
import encryptData from "../../../services/encryptData";
import decryptData from "../../../services/decryptData";
import { Dropdown } from "primereact/dropdown";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { SocketContext } from "../../../../../Context";
import { Dialog } from "primereact/dialog";
import VideoCall from "../../videoCall/pages";
import { toast } from "react-toastify";

function Support_chat({ permissions }) {
    const [status, setStatus] = useState("");

    const [images, setImages] = useState([]);

    const messagesEndRef = useRef(null);

    const fileInput = useRef(null);

    const handleButtonClick = (e) => {
        e.preventDefault();
        fileInput.current.click();
    };

    const loginUser = useSelector((state) => state.loginUser);
    const { user } = loginUser;

    const { socket } = useContext(SocketContext);
    const [userToCall, setUserToCall] = useState(null);

    const [message, setMessage] = useState("");
    const params = useParams();
    const { id } = params;

    //usestate hooks
    const [loading, setLoading] = useState(false);
    const [chatData, setChatData] = useState();

    //
    useEffect(() => {
        setTimeout(() => {
            socket.emit(socketsPoint.ticketChatEmitter, JSON.stringify(encryptData({ ticketId: id, isAdmin: true })), (data) => {
                data = decryptData(data?.cipher);
                setLoading(false);
                data = data?.data;
                setChatData(data);
                setStatus(data?.status);
                // toast.info("New message recieved");
                // scrollToBottom();
            });
            socket.on(socketsPoint.newSupportMessageListener, (response) => {
                response = decryptData(response?.cipher);
                setChatData((prev) => {
                    prev.chat.push(response.data.chat);
                    return { ...prev };
                });
                toast.info("New message received");
                // scrollToBottom();  // Uncomment if needed for auto-scrolling
            });
            socket.on(socketsPoint.newSupportAttachmentListener, (response) => {
                response = decryptData(response?.cipher);

                setChatData((prev) => {
                    prev.chat[prev.chat.length - 1].attachments.push(response.data.attachment);
                    return { ...prev };
                });
            });
        }, 3000);

        return () => {
            socket.off(socketsPoint.ticketChatEmitter);
            socket.off(socketsPoint.newSupportMessageListener);
            socket.off(socketsPoint.newSupportAttachmentListener);
        };
    }, [id]);

    useEffect(() => {
        if (chatData) {
            const keyValueData = Object.entries(chatData?.userId).map(([key, value]) => ({
                key,
                value,
            }));
        }
    }, [chatData]);

    const scrollToBottom = () => {
        messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    };

    const sendNewMessage = async (e) => {
        e.preventDefault();
        let data = {
            ticketId: chatData._id,
            text: message,
            attachments: [],
            isAdmin: true,
            sendBy: user._id,
        };

        socket.emit(socketsPoint.newSupportMessageEmitter, JSON.stringify(encryptData(data)), (response) => {
            setMessage("");
            response = decryptData(response?.cipher);
            let ticketId = chatData._id;
            let messageId = response.data._id;

            for (var item of images) {
                let data = {
                    ticketId: ticketId,
                    messageId: messageId,
                    attachment: item,
                };
                socket.emit(socketsPoint.addAttachement, JSON.stringify(encryptData(data)), (res) => {});
            }
            setImages([]);
        });
    };

    const handleChange = async (event) => {
        event.preventDefault();

        let temp = [];
        const files = event.target.files;
        for (var i = 0; i < files.length; i++) {
            let base64 = await fileServices.convertFileToBase64(files[i]);
            temp.push(base64);
        }

        setImages((prev) => {
            return [...prev, ...temp];
        });
    };

    const updateSupportStatus = (status) => {
        const data = { ticketId: chatData._id, status };
        ////console.log(data);

        socket.emit(socketsPoint.updateStatusSupportTicket, JSON.stringify(encryptData(data)), (response) => {
            // response = decryptData(response?.cipher);
            setChatData((prev) => {
                prev.status = status;
                return { ...prev };
            });
            ////console.log(response);
        });
    };

    const handleStatusSubmit = (e) => {
        e.preventDefault();
        updateSupportStatus(status);
    };

    const userTypeTemplate = (userType) => {
        if (userType == 3) {
            return "Both";
        } else if (userType == 2) {
            return "Passenger";
        } else if (userType == 1) {
            return "Owner";
        } else {
            return "";
        }
    };

    return (
        <>
            {loading || chatData === undefined ? (
                <CustomLoading />
            ) : (
                <>
                    <Dialog
                        header={`Calling ${chatData?.userId?.firstName} ${chatData?.userId?.lastName}`}
                        visible={userToCall != null}
                        style={{ width: "auto", height: "100vh" }}
                        onHide={() => {
                            setUserToCall(null);
                            window.location.reload();
                        }}
                    >
                        <VideoCall
                            callerName={"Rahper Support"}
                            onHide={() => {
                                setUserToCall(null);
                                window.location.reload();
                            }}
                            userToCall={`${chatData?.userId?.firstName} ${chatData?.userId?.lastName}`}
                            userId={userToCall}
                        />
                    </Dialog>
                    <div className="grid p-fluid">
                        <div className="col-12 lg:col-8">
                            <div className=" grid-container">
                                <div className="top-header card">
                                    <h4>{chatData.subject}</h4>
                                </div>

                                <div className="messagesBox card">
                                    {chatData.chat.map((item) => (
                                        <ChatBubble permissions={permissions} key={item._id} userId={chatData.userId._id} fullName={`${chatData.userId.firstName} ${chatData.userId.lastName}`} chat={item} />
                                    ))}
                                    <div ref={messagesEndRef} />
                                </div>
                                {chatData?.status == "open" ? (
                                    <div style={{ height: images.length === 0 ? "6rem" : "12rem" }} className={`chatBox card`}>
                                        {images.length > 0 && (
                                            <div className="flex flex-row mb-2">
                                                {images.map((item, index) => {
                                                    return (
                                                        <div key={index} className="relative mr-2">
                                                            <Image preview height="50px" width="50px" src={item} alt="Load error" />
                                                            <Button
                                                                onClick={() => {
                                                                    setImages((prev) => {
                                                                        prev.splice(index, 1);
                                                                        return [...prev];
                                                                    });
                                                                }}
                                                                style={{ color: "red", top: "-20px", right: "-20px" }}
                                                                icon="pi pi-trash"
                                                                className="p-button-rounded p-button-danger p-button-rounded p-button-text absolute"
                                                                aria-label="Cancel"
                                                            />
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        )}

                                        <input type="file" onChange={handleChange} multiple accept="images/*" style={{ display: "none" }} ref={fileInput} />

                                        <form onSubmit={sendNewMessage}>
                                            <div className="flex">
                                                <div className="flex-grow-1">
                                                    <div className="p-inputgroup">
                                                        <InputText required name="message" onChange={(e) => setMessage(e.target.value)} value={message} placeholder="Message" />
                                                    </div>
                                                </div>
                                                <div className="ml-3">
                                                    <div className="p-inputgroup">
                                                        <Button type="submit" icon="pi pi-angle-right" className="p-button-primary" />
                                                    </div>
                                                </div>
                                                <div className="ml-3">
                                                    <div className="p-inputgroup">
                                                        <Button icon="pi pi-images" onClick={handleButtonClick} className="p-button-rounded p-button-text" aria-label="Submit" />
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>

                        <div className="col-12 lg:col-4">
                            <div className="card">
                                <div className="grid">
                                    <div className="col-2">
                                        <Image className="mr-2" preview style={{ borderRadius: "50%" }} height="50px" width="50px" src={`${appUrl.baseUrl}${chatData.userId.profileImage}`} />
                                    </div>
                                    <div className="col-8">
                                        <div>
                                            <h5>
                                                {chatData.userId.firstName} {chatData.userId.lastName}
                                            </h5>
                                        </div>
                                        <div>
                                            <h6>{chatData.userId.active.status ? "Active" : "Blocked"}</h6>
                                        </div>
                                    </div>
                                    <div className="col-2">
                                        <Button
                                            icon="pi pi-phone"
                                            onClick={() => {
                                                setUserToCall(chatData?.userId?._id);
                                            }}
                                        />
                                    </div>
                                </div>

                                <div>Ticket Status : {chatData.status === "open" ? <span className="text-red-500">Opened</span> : <span className="text-primary">Closed</span>}</div>

                                <div>Ticket created on : {formatDate(chatData.createdAt)}</div>

                                <form onSubmit={handleStatusSubmit}>
                                    <div className="col-12">
                                        <div className="flex flex-row align-content-end gap-2">
                                            <Dropdown
                                                value={status}
                                                options={[
                                                    { name: "Open", value: "open" },
                                                    { name: "Close", value: "close" },
                                                ]}
                                                optionLabel="name"
                                                className="w-full md:w-14rem"
                                                onChange={(e) => {
                                                    setStatus(e.target.value);
                                                }}
                                            />

                                            <div className="flex flex-column justify-content-center ml-2">
                                                <Button className="col-2" disabled={chatData.status === "open" ? (status === "open" ? true : false) : status === "close" ? true : false} label="Update" type="submit" aria-label="Submit" />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="card">
                                <div className="flex flex-row" style={{ width: "100%" }}>
                                    <div className="flex flex-column" style={{ width: "100%" }}>
                                        <div>
                                            <h5>Details</h5>
                                            <div className="grid">
                                                <div className="col-4">UserType</div>
                                                <div className="col-8">{userTypeTemplate(chatData?.userId?.userType)}</div>
                                            </div>
                                            <div className="grid">
                                                <div className="col-4">Status</div>
                                                <div className="col-8">{chatData?.userId?.active ? "Active" : "In-Active"}</div>
                                            </div>
                                            <div className="grid">
                                                <div className="col-4">Mobile</div>
                                                <div className="col-8">{chatData?.userId?.mobile}</div>
                                            </div>
                                            <div className="grid">
                                                <div className="col-4">Email</div>
                                                <div className="col-8">{chatData?.userId?.email}</div>
                                            </div>
                                            <div className="grid">
                                                <div className="col-4">Gender</div>
                                                <div className="col-8">{chatData?.userId?.gender}</div>
                                            </div>
                                            <div className="grid">
                                                <div className="col-4">CNIC</div>
                                                <div className="col-8">{chatData?.userId?.cnic}</div>
                                            </div>
                                            <div className="grid">
                                                <div className="col-4">Ratings</div>
                                                <div className="col-8">{chatData?.userId?.totalRatingCount && (chatData?.userId?.totalRating / chatData?.userId?.totalRatingCount).toFixed(2)}</div>
                                            </div>
                                            <div className="grid">
                                                <div className="col-4">DOB</div>
                                                <div className="col-8">{chatData?.userId?.dob}</div>
                                            </div>
                                            <div className="grid">
                                                <div className="col-4">selected UserType</div>
                                                <div className="col-8">{userTypeTemplate(chatData?.userId?.selectedUserType)}</div>
                                            </div>{" "}
                                            <div className="grid">
                                                <div className="col-4">Login Device</div>
                                                <div className="col-8">{userTypeTemplate(chatData?.userId?.loginDevice)}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
}

export default Support_chat;
