import decryptData from '../services/decryptData'
import encryptData from '../services/encryptData'
import appUrl from './appUrl'

const axios = require('axios')
const axiosApi = axios.create()

// Request interceptor for API calls
axiosApi.interceptors.request.use(
  async (config) => {
    const access_token = localStorage.getItem('accessToken')
    config.headers = {
      Authorization: `Bearer ${access_token}`,
      // 'Accept': 'application/json',
      // 'Content-Type': 'application/x-www-form-urlencoded'
    }
    config.data = encryptData(config.data)

    // //console.log(config.data);
    return config
  },
  (error) => {
    Promise.reject(error)
  },
)
// Response interceptor for API calls
axiosApi.interceptors.response.use(
  (response) => {
    // //console.log("response");
    // console.log(response.data);
    response.data = decryptData(response.data.cipher)
    // //console.log(response.data);
    return response
  },
  async function (error) {
    // //console.log("error");
    // console.log(error.response.data);
    error.response.data = decryptData(error.response.data.cipher)
    // //console.log(error.response.data);
    const originalRequest = error.config
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true
      const access_token = await refreshAccessToken()
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token
      return axiosApi(originalRequest)
    }
    return Promise.reject(error)
  },
)


// const refreshAccessToken=async()=>{
//   const refreshToken = localStorage.getItem('refreshToken');

//   if (refreshToken) {
//         const data=await axiosApi.post(`${appUrl.baseUrl}api/web/refreshToken`,{
//             refreshToken:refreshToken
//         });
      
//       const response = data?.data?.cipher
//       const tok = decryptData(response)
//       const accessToken = tok?.data?.token
//       localStorage.setItem('accessToken', accessToken)
      
//         return accessToken
//     }else{
//         return '';
//     }
// }

let isRefreshing = false;
let refreshPromise = null;

const refreshAccessToken = async () => {
  if (isRefreshing) {
    // If a refresh is already in progress, wait for it to complete
    return refreshPromise;
  }

  const refreshToken = localStorage.getItem('refreshToken');

  if (refreshToken) {
    isRefreshing = true;
    refreshPromise = new Promise(async (resolve, reject) => {
      try {
        const data = await axiosApi.post(`${appUrl.baseUrl}api/web/refreshToken`, {
          refreshToken: refreshToken
        });

        const response = data?.data?.cipher;
        const tok = decryptData(response);
        const accessToken = tok?.data?.token;
        localStorage.setItem('accessToken', accessToken);

        resolve(accessToken);
      } catch (error) {
        reject(error);
      } finally {
        isRefreshing = false;
        refreshPromise = null;
      }
    });

    return refreshPromise;
  } else {
    return '';
  }
};


export { axiosApi }
