import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getRideDetails } from "../../../../../redux/actions/rides_actions";
import { DataTable } from "primereact/datatable";
import { Image } from "primereact/image";
import appUrl from "../../../constants/appUrl";
import { Column } from "primereact/column";
import { loadGoogleMaps, removeGoogleMaps } from "./GoogleMaps";
import { GMap } from "primereact/gmap";
import CustomLoading from "../../../components/custom_loading";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import ReceiptDialog from "../../../components/receipt_dialog";
import moment from "moment";

export default function DriverRideDetailsScreen({ permissions }) {
    const google = window.google;

    var mapRef = useRef(null);
    const toast = useRef(null);
    const infoWindow = useRef(null);
    const [googleMapsReady, setGoogleMapsReady] = useState(false);

    const [passengers, setPassengers] = useState([]);
    const [showReceiptDialog, setReceiptDialog] = useState(false);
    const [receiptId, setReceiptId] = useState("");
    const [test] = useState();
    const dialogFuncMap = {
        showReceiptDialog: setReceiptDialog,
    };
    const onClick = (name) => {
        dialogFuncMap[`${name}`](true);
    };
    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    };

    useEffect(() => {
        loadGoogleMaps(() => {
            setGoogleMapsReady(true);
        });

        return () => {
            removeGoogleMaps();
        };
    }, [test]);

    //redux
    const getRideDetailsReducer = useSelector((state) => state.getRideDetails);
    const { ride, loading } = getRideDetailsReducer;
    const dispatch = useDispatch();
    const params = useParams();
    const { id } = params;

    const [overlays, setOverlays] = useState([]);

    const onOverlayClick = (event) => {
        let isMarker = event.overlay.getTitle !== undefined;

        if (isMarker) {
            let title = event.overlay.getTitle();

            infoWindow.current = infoWindow.current || new window.google.maps.InfoWindow();
            infoWindow.current.setContent("<div>" + title + "</div>");
            infoWindow.current.open(event.map, event.overlay);
            event.map.setCenter(event.overlay.getPosition());
        } else {
        }
    };

    const handleDragEnd = (event) => {
        toast.current.show({ severity: "info", summary: "Marker Dragged", detail: event.overlay.getTitle() });
    };

    useEffect(() => {
        dispatch(getRideDetails(id));
    }, [test]);

    const onMapClick = () => {};

    const onMarkerClick = () => {};

    const addPolyline = (list) => {
        let newPolyline = new google.maps.Polyline({
            path: list,
            geodesic: true,
            strokeColor: "#00FF00",
            strokeOpacity: 0.8,
            strokeWeight: 2,
        });
        setOverlays((prev) => [...prev, newPolyline]);
    };

    const addMarkerOwnerStart = (lat, lng, title, green = false, placeName) => {
        let newMarker;
        if (title == "") {
            newMarker = new google.maps.Marker({
                position: {
                    lat: parseFloat(lat),
                    lng: parseFloat(lng),
                },
                onClick: onMarkerClick,
                id: id,
                title: "Current location",
                draggable: false,
                icon: {
                    url: "/assets/cab.png",
                    fillOpacity: 1.0,
                    strokeWeight: 1,
                    scale: 1,
                    anchor: new google.maps.Point(12, 24),
                },
            });

            setOverlays((prev) => [...prev, newMarker]);
        } else {
            newMarker = new google.maps.Marker({
                position: {
                    lat: lat,
                    lng: lng,
                },
                onClick: onMarkerClick,
                title: title,
                draggable: false,
                icon: !green
                    ? null
                    : {
                          path: "m 12,2.4000002 c -2.7802903,0 -5.9650002,1.5099999 -5.9650002,5.8299998 0,1.74375 1.1549213,3.264465 2.3551945,4.025812 1.2002732,0.761348 2.4458987,0.763328 2.6273057,2.474813 L 12,24 12.9825,14.68 c 0.179732,-1.704939 1.425357,-1.665423 2.626049,-2.424188 C 16.809241,11.497047 17.965,9.94 17.965,8.23 17.965,3.9100001 14.78029,2.4000002 12,2.4000002 Z",
                          fillColor: "#00FF00",
                          fillOpacity: 1.0,
                          strokeColor: "#000000",
                          strokeWeight: 1.5,
                          scale: 2,
                          anchor: new google.maps.Point(12, 24),
                      },
            });

            setOverlays((prev) => [...prev, newMarker]);
        }
        const infoWindow = new window.google.maps.InfoWindow({
            content: `
           <div>
           <p><b>Owner</b></p>
         <p><b>Name: </b>${ride?.userId?.firstName.charAt(0).toUpperCase() + ride?.userId?.firstName.slice(1)} ${ride?.userId?.lastName.charAt(0).toUpperCase() + ride?.userId?.lastName.slice(1)}</p>
        <p><b>Start Location: </b>${placeName}</p>
         </div>
           `,
        });

        const func = () => {
            return <div></div>;
        };

        // Open the info window on marker hover
        newMarker.addListener("mouseover", () => {
            infoWindow.open(func, newMarker);
        });

        // Close the info window on marker mouseout
        newMarker.addListener("mouseout", () => {
            infoWindow.close();
        });
    };
    const addMarkerOwnerEnd = (lat, lng, title, green = false, placeName) => {
        let newMarker;
        if (title == "") {
            newMarker = new google.maps.Marker({
                position: {
                    lat: parseFloat(lat),
                    lng: parseFloat(lng),
                },
                onClick: onMarkerClick,
                id: id,
                title: "Current location",
                draggable: false,
                icon: {
                    url: "/assets/cab.png",

                    // fillColor: '#00FF00',
                    fillOpacity: 1.0,
                    // strokeColor: '#000000',
                    strokeWeight: 1,
                    scale: 1,
                    anchor: new google.maps.Point(12, 24),
                },
            });

            setOverlays((prev) => [...prev, newMarker]);
        } else {
            newMarker = new google.maps.Marker({
                position: {
                    lat: lat,
                    lng: lng,
                },
                onClick: onMarkerClick,
                title: title,
                draggable: false,
                icon: !green
                    ? null
                    : {
                          path: "m 12,2.4000002 c -2.7802903,0 -5.9650002,1.5099999 -5.9650002,5.8299998 0,1.74375 1.1549213,3.264465 2.3551945,4.025812 1.2002732,0.761348 2.4458987,0.763328 2.6273057,2.474813 L 12,24 12.9825,14.68 c 0.179732,-1.704939 1.425357,-1.665423 2.626049,-2.424188 C 16.809241,11.497047 17.965,9.94 17.965,8.23 17.965,3.9100001 14.78029,2.4000002 12,2.4000002 Z",
                          fillColor: "#00FF00",
                          fillOpacity: 1.0,
                          strokeColor: "#000000",
                          strokeWeight: 1.5,
                          scale: 2,
                          anchor: new google.maps.Point(12, 24),
                      },
            });
            setOverlays((prev) => [...prev, newMarker]);
        }
        // Create an info window
        const infoWindow = new window.google.maps.InfoWindow({
            content: `
           <div>
           <p><b>Owner</b></p>
         <p><b>Name: </b>${ride?.userId?.firstName.charAt(0).toUpperCase() + ride?.userId?.firstName.slice(1)} ${ride?.userId?.lastName.charAt(0).toUpperCase() + ride?.userId?.lastName.slice(1)}</p>
        <p><b>End Location: </b>${placeName}</p>
         </div>
           `,
        });

        const func = () => {
            return <div></div>;
        };

        // Open the info window on marker hover
        newMarker.addListener("mouseover", () => {
            infoWindow.open(func, newMarker);
        });

        // Close the info window on marker mouseout
        newMarker.addListener("mouseout", () => {
            infoWindow.close();
        });
    };
    const onMapReady = (event) => {
        setOverlays([
            // new google.maps.Marker({position: {lat: 36.879466, lng: 30.667648}, title:"Konyaalti"}),
            // new google.maps.Marker({position: {lat: 36.883707, lng: 30.689216}, title:"Ataturk Park"}),
            // new google.maps.Marker({position: {lat: 36.885233, lng: 30.702323}, title:"Oldtown"}),
            // new google.maps.Polygon({paths: [
            //     {lat: 36.9177, lng: 30.7854},{lat: 36.8851, lng: 30.7802},{lat: 36.8829, lng: 30.8111},{lat: 36.9177, lng: 30.8159}
            // ], strokeOpacity: 0.5, strokeWeight: 1, fillColor: '#1976D2', fillOpacity: 0.35
            // }),
            // new google.maps.Circle({center: {lat: 36.90707, lng: 30.56533}, fillColor: '#1976D2', fillOpacity: 0.35, strokeWeight: 1, radius: 1500}),
            // new google.maps.Polyline({path: [{lat: 36.86149, lng: 30.63743},{lat: 36.86341, lng: 30.72463}], geodesic: true, strokeColor: '#FF0000', strokeOpacity: 0.5, strokeWeight: 2})
        ]);
    };

    //map marker 2
    const addPolyline2 = (list) => {
        let newPolyline = new google.maps.Polyline({
            path: list,
            geodesic: true,
            strokeColor: "#FF0000",
            strokeOpacity: 0.5,
            strokeWeight: 2,
        });
        setOverlays((prev) => [...prev, newPolyline]);
    };

    const addMarkerPassengerStart = (lat, lng, title, green = false, placeName, startTime, endTime) => {
        let newMarker;
        if (title == "") {
            newMarker = new google.maps.Marker({
                position: {
                    lat: parseFloat(lat),
                    lng: parseFloat(lng),
                },
                onClick: onMarkerClick,
                id: id,
                title: "Current location",
                draggable: false,
                icon: {
                    url: "/assets/cab.png",

                    //fillColor: '#00FF00',
                    fillOpacity: 1.0,
                    // strokeColor: '#000000',
                    strokeWeight: 1,
                    scale: 1,
                    anchor: new google.maps.Point(12, 24),
                },
            });

            setOverlays((prev) => [...prev, newMarker]);
        } else {
            newMarker = new google.maps.Marker({
                position: {
                    lat: lat,
                    lng: lng,
                },
                onClick: onMarkerClick,
                title: title,
                draggable: false,
                icon: !green
                    ? null
                    : {
                          path: "m 12,2.4000002 c -2.7802903,0 -5.9650002,1.5099999 -5.9650002,5.8299998 0,1.74375 1.1549213,3.264465 2.3551945,4.025812 1.2002732,0.761348 2.4458987,0.763328 2.6273057,2.474813 L 12,24 12.9825,14.68 c 0.179732,-1.704939 1.425357,-1.665423 2.626049,-2.424188 C 16.809241,11.497047 17.965,9.94 17.965,8.23 17.965,3.9100001 14.78029,2.4000002 12,2.4000002 Z",
                          fillColor: "#0000FF",
                          fillOpacity: 1.0,
                          strokeColor: "#000000",
                          strokeWeight: 1.5,
                          scale: 2,
                          anchor: new google.maps.Point(12, 24),
                      },
            });

            setOverlays((prev) => [...prev, newMarker]);
        }

        // Create an info window
        const infoWindow = new window.google.maps.InfoWindow({
            content: `
            <div>
            <p><b>Passenger</b></p>
            <p><b>Name: </b>${ride?.accepted?.map((arr) => arr?.userId?.firstName.charAt(0).toUpperCase() + arr?.userId?.firstName.slice(1))} ${ride?.accepted?.map((arr) => arr?.userId?.lastName.charAt(0).toUpperCase() + arr?.userId?.lastName.slice(1))}</p>
            <p><b>Pickup Location:</b>${placeName}</p>
            <p><b>Started:</b>${startTime}</p>
            <p><b>Ended:</b>${endTime}</p>
          </div>
            `,
        });

        const func = () => {
            return <div></div>;
        };
        // Open the info window on marker hover
        newMarker.addListener("click", () => {
            infoWindow.open(func, newMarker);
        });
        // Open the info window on marker hover
        newMarker.addListener("mouseover", () => {
            infoWindow.open(func, newMarker);
        });

        // Close the info window on marker mouseout
        newMarker.addListener("mouseout", () => {
            infoWindow.close();
        });
    };
    const addMarkerPassengerEnd = (lat, lng, title, green = false, placeName, startTime, endTime) => {
        let newMarker;
        if (title == "") {
            newMarker = new google.maps.Marker({
                position: {
                    lat: parseFloat(lat),
                    lng: parseFloat(lng),
                },
                onClick: onMarkerClick,
                id: id,
                title: "Current location",
                draggable: false,
                icon: {
                    url: "/assets/cab.png",

                    // fillColor: '#00FF00',
                    fillOpacity: 1.0,
                    // strokeColor: '#000000',
                    strokeWeight: 1,
                    scale: 1,
                    anchor: new google.maps.Point(12, 24),
                },
            });

            setOverlays((prev) => [...prev, newMarker]);
        } else {
            newMarker = new google.maps.Marker({
                position: {
                    lat: lat,
                    lng: lng,
                },
                onClick: onMarkerClick,
                title: title,
                draggable: false,
                icon: !green
                    ? null
                    : {
                          path: "m 12,2.4000002 c -2.7802903,0 -5.9650002,1.5099999 -5.9650002,5.8299998 0,1.74375 1.1549213,3.264465 2.3551945,4.025812 1.2002732,0.761348 2.4458987,0.763328 2.6273057,2.474813 L 12,24 12.9825,14.68 c 0.179732,-1.704939 1.425357,-1.665423 2.626049,-2.424188 C 16.809241,11.497047 17.965,9.94 17.965,8.23 17.965,3.9100001 14.78029,2.4000002 12,2.4000002 Z",
                          fillColor: "#0000FF",
                          fillOpacity: 1.0,
                          strokeColor: "#000000",
                          strokeWeight: 1.5,
                          scale: 2,
                          anchor: new google.maps.Point(12, 24),
                      },
            });

            setOverlays((prev) => [...prev, newMarker]);
        }

        // Create an info window
        const infoWindow = new window.google.maps.InfoWindow({
            content: `
            <div>
            <p><b>Passenger</b></p>
           <p><b>Name: </b>${ride?.accepted?.map((arr) => arr?.userId?.firstName.charAt(0).toUpperCase() + arr?.userId?.firstName.slice(1))} ${ride?.accepted?.map((arr) => arr?.userId?.lastName.charAt(0).toUpperCase() + arr?.userId?.lastName.slice(1))}</p>
            <p><b>Dropoff Location: </b>${placeName}</p>
            <p><b>Started:</b>${startTime}</p>
            <p><b>Ended:</b>${endTime}</p>
          </div>
            `,
        });

        const func = () => {
            return <div></div>;
        };

        // Open the info window on marker hover
        newMarker.addListener("mouseover", () => {
            infoWindow.open(func, newMarker);
        });

        // Close the info window on marker mouseout
        newMarker.addListener("mouseout", () => {
            infoWindow.close();
        });
    };
    useEffect(() => {
        if (loading !== undefined && loading === false && ride != undefined && googleMapsReady === true) {
            addMarkerOwnerStart(ride?.startPoint?.latitude, ride?.startPoint?.longitude, ride?.startPoint?.placeName);
            addMarkerOwnerEnd(ride?.endPoint?.latitude, ride?.endPoint?.longitude, ride?.endPoint?.placeName, true, ride?.endPoint?.placeName);
            addMarkerOwnerStart(ride?.lastLocation[ride?.lastLocation?.length - 1]?.latitude, ride?.lastLocation[ride?.lastLocation?.length - 1]?.longitude, "", true, ride?.startPoint?.placeName);
            addPolyline(ride?.polyline?.map((item) => ({ lat: item[0], lng: item[1] })));
            for (const passenger of ride?.accepted) {
                const startTime = moment(passenger?.startTime).format("MMMM Do YYYY, h:mm a");
                const endTime = moment(passenger?.endTime).format("MMMM Do YYYY, h:mm a");
                addMarkerPassengerStart(passenger?.startPoint?.latitude, passenger?.startPoint?.longitude, passenger?.startPoint?.placeName, true, passenger?.startPoint?.placeName, startTime, endTime);
                addMarkerPassengerEnd(passenger?.endPoint?.latitude, passenger?.endPoint?.longitude, passenger?.endPoint?.placeName, true, passenger?.endPoint?.placeName, startTime, endTime);
                addPolyline2(passenger?.polyline?.map((item) => ({ lat: item[0], lng: item[1] })));
            }
            setPassengers([...ride.request, ...ride.myRequests]);
            const bounds = new window.google.maps.LatLngBounds();
            bounds.extend(new window.google.maps.LatLng(ride.bounds_sw.latitude, ride.bounds_sw.longitude));
            bounds.extend(new window.google.maps.LatLng(ride.bounds_ne.latitude, ride.bounds_ne.longitude));
            mapRef.map.fitBounds(bounds);
        }
    }, [ride, loading]);

    return (
        <>
            {loading === true || loading === undefined ? (
                <div>
                    <CustomLoading />
                </div>
            ) : (
                <div style={{ width: "100%" }}>
                    <Dialog position="center" className="text-center" visible={showReceiptDialog} breakpoints={{ "960px": "75vw" }} style={{ width: "40vw" }} onHide={() => onHide("showReceiptDialog")}>
                        <div>{receiptId != null && <ReceiptDialog permissions={permissions} receiptId={receiptId} />}</div>
                    </Dialog>
                    {googleMapsReady && (
                        <div className="card">
                            <GMap
                                ref={(ref) => {
                                    mapRef = ref;
                                }}
                                overlays={overlays}
                                //   options={options}
                                style={{ width: "100%", minHeight: "320px" }}
                                onMapReady={onMapReady}
                                onMapClick={onMapClick}
                                onOverlayClick={onOverlayClick}
                                onOverlayDragEnd={handleDragEnd}
                            />
                        </div>
                    )}

                    <div className="card">
                        <h3>Owner</h3>
                        {ride?.userId && (
                            <DataTable responsiveLayout="scroll" value={[ride.userId]} key="_id" emptyMessage="No record found.">
                                <Column
                                    className="maker_colm"
                                    header="Image"
                                    body={(data) => {
                                        return (
                                            <div>
                                                <Image imageStyle={{ borderRadius: "50%", width: "50px", height: "50px" }} src={`${appUrl.fileBaseUrl}${data?.profileImage}`} />
                                            </div>
                                        );
                                    }}
                                />
                                <Column className="maker_colm" field="firstName" header="First name" />
                                <Column className="maker_colm" field="lastName" header="Last name" />
                                <Column className="maker_colm" field="mobile" header="Mobile" />
                                <Column className="maker_colm" field="gender" header="Gender" />
                            </DataTable>
                        )}
                        <h3>Vehicle</h3>
                        {ride?.vehicleId && (
                            <DataTable responsiveLayout="scroll" value={[ride.vehicleId]} key="vehicle">
                                <Column className="maker_colm" field="model.model" header="Model" />
                                <Column className="maker_colm" field="model.make.make" header="Maker" />
                                <Column className="maker_colm" field="model.seatingCapacity" header="Seating Capacity" />
                                <Column className="maker_colm" field="year" header="Year" />
                                <Column className="maker_colm" field="registrationNumber" header="No." />
                                <Column className="maker_colm" field="registrationCity.city" header="City" />
                                <Column className="maker_colm" field="registrationProvince.name" header="Province" />
                                <Column className="maker_colm" field="minMileage" header="Average Mileage(Km)" />
                            </DataTable>
                        )}

                        <h3>Passengers</h3>
                        <DataTable responsiveLayout="scroll" value={passengers} key="pasengers">
                            <Column
                                className="maker_colm"
                                header="Image"
                                body={(data) => {
                                    return (
                                        <div>
                                            <Image imageStyle={{ borderRadius: "50%", width: "50px", height: "50px" }} src={`${appUrl.fileBaseUrl}${data?.userId?.profileImage}`} />
                                        </div>
                                    );
                                }}
                            />
                            <Column className="maker_colm" field="userId.firstName" header="First name" />
                            <Column className="maker_colm" field="userId.lastName" header="Last name" />
                            <Column className="maker_colm" field="startPoint.placeName" header="Pickup Location" />
                            <Column className="maker_colm" field="endPoint.placeName" header="Dropoff location" />
                            <Column className="maker_colm" field="gender" header="Gender" />
                            <Column className="maker_colm" field="fare" header="Fare" />
                            <Column
                                className="maker_colm"
                                header="Status"
                                body={(data) => {
                                    if (ride.accepted.some((item) => item === data._id)) {
                                        return <div className="text-primary">Accepted</div>;
                                    }
                                    if (ride.completed.some((item) => item === data._id)) {
                                        return <div className="text-green-600">Completed</div>;
                                    }
                                    if (ride.cancelled.some((item) => item === data._id)) {
                                        return <div className="text-red-600">Cancelled</div>;
                                    }
                                    if (ride.rejected.some((item) => item === data._id)) {
                                        return <div className="text-red-600">Rejected</div>;
                                    } else {
                                        return "Pending";
                                    }
                                }}
                            />

                            <Column
                                className="maker_colm"
                                body={(data) => (
                                    <div>
                                        {ride?.status === "completed" && (
                                            <Button
                                                tooltip="View Receipts"
                                                icon="pi pi-money-bill"
                                                tooltipOptions={{ position: "top" }}
                                                className="edit"
                                                onClick={() => {
                                                    setReceiptId(ride._id);
                                                    onClick("showReceiptDialog");
                                                }}
                                            />
                                        )}
                                    </div>
                                )}
                                header="Receipt"
                            />
                        </DataTable>
                    </div>
                </div>
            )}
        </>
    );
}
