import Axios from "axios";
import appUrl from "../../app/features/constants/appUrl";
import { axiosApi } from "../../app/features/constants/axios_api";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

const vehicleModelSlice = createSlice({
    name: "vehicleModel",
    initialState: {},
    reducers: {
        resetAddEditVehicleModels(state, action) {
            return { ...state, addSuccess: undefined, updateSuccess: undefined, deleteModelSuccess: undefined };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(vehicleModelList.pending, (state, action) => {
                return { ...state, loading: true };
            })
            .addCase(vehicleModelList.fulfilled, (state, action) => {
                return { ...state, loading: false, data: action.payload, success: true };
            })
            .addCase(vehicleModelList.rejected, (state, action) => {
                return {
                    ...state,
                    loading: false,
                    error: action.payload,
                    success: false,
                };
            });
        builder
            .addCase(addVehicleModels.pending, (state, action) => {
                return { ...state, addLoading: true };
            })
            .addCase(addVehicleModels.fulfilled, (state, action) => {
                // //console.log(action.payload);

                return { ...state, addLoading: false, addData: action.payload, addSuccess: true };
            })
            .addCase(addVehicleModels.rejected, (state, action) => {
                return {
                    ...state,
                    addLoading: false,
                    addError: action.payload,
                    addSuccess: false,
                };
            });
        builder
            .addCase(updateVehicleModels.pending, (state, action) => {
                return { ...state, updateLoading: true }
            })
            .addCase(updateVehicleModels.fulfilled, (state, action) => {

                return { ...state, updateLoading: false, updateData: action.payload, updateSuccess: true }
            })
            .addCase(updateVehicleModels.rejected, (state, action) => {

                return {
                    ...state,
                    updateLoading: false,
                    updateError: action.payload,
                    updateSuccess: false
                }
            });
            builder
            .addCase(deleteVehicleModel.pending, (state, action) => {
                return { ...state, deleteLoading: true }
            })
            .addCase(deleteVehicleModel.fulfilled, (state, action) => {

                return { ...state, deleteLoading: false, deleteData: action.payload,deleteModelSuccess:true }
            })
            .addCase(deleteVehicleModel.rejected, (state, action) => {

                return {
                    ...state, deleteLoading: false,
                    error: action.payload,
                    deleteModelSuccess:false
                }
            });
    },
});
export default vehicleModelSlice.reducer;
export const { resetAddEditVehicleModels } = vehicleModelSlice.actions;

// Thunks
export const vehicleModelList = createAsyncThunk("vehicleModel/add", async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosApi.post(appUrl.baseUrl + appUrl.vehicleModel, body);
        //console.log("vehicel list", data)
        return fulfillWithValue(data.data);
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.msg ? error.response.data.msg : error.message);
    }
});
export const addVehicleModels = createAsyncThunk("addVehicleModels/add", async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosApi.post(appUrl.baseUrl + appUrl.addVehicleModels, body);
        return fulfillWithValue(data.data);
    } catch (error) {
        throw rejectWithValue(error.response && error.response.data.msg ? error.response.data.msg : error.message);
    }
});

export const updateVehicleModels = createAsyncThunk('vehicleModels/update', async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosApi.patch(appUrl.baseUrl + appUrl.updateVehicleModels, body);
        return fulfillWithValue(data.data);
    } catch (error) {

        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)

    }

});
export const deleteVehicleModel = createAsyncThunk('ads/delete', async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
   
        const { data } = await axiosApi.delete(appUrl.baseUrl + appUrl.deleteVehicleModel+`?modelId=${body.modelId}`);
        return fulfillWithValue(data.data);
    } catch (error) {

        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)

    }
});