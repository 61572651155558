import React, { useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import CustomInputField from "../../../components/custom_input_filed";
import { Button } from "primereact/button";
import { useDispatch, useSelector } from "react-redux";
import { activeDeActiveSubModule, resetModulesList } from "../../../../../redux/slices/modules_slice";

export default function SubModuleStatusChange({ onHide, editData, permissions }) {
    const dispatch = useDispatch();

    // Redux Selector
    const modulesListReducer = useSelector((state) => state.modulesList);

    const { activeSubModuleSuccess } = modulesListReducer;

    // Validation Schema
    const validationSchema = Yup.object().shape({
        active: Yup.string().required("Status is required"),
    });

    // Formik
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            active: "",
        },
        onSubmit: async (data) => {
            const body = {
                subModuleId: editData?._id,
                active: data?.active,
            };
            dispatch(activeDeActiveSubModule(body));
        },
    });

    const statusValue = [
        { label: "Active", value: true },
        { label: "Inactive", value: false },
    ];

    useEffect(() => {
        if (editData) {
            formik.setFieldValue("active", editData.active);
        }
    }, [editData]);

    // Handle success and reset state
    useEffect(() => {
        if (activeSubModuleSuccess) {
            onHide(); // Close modal
            dispatch(resetModulesList()); // Reset success flag
        }
    }, [activeSubModuleSuccess, onHide, dispatch]);

    return (
        <div>
            <form onSubmit={formik.handleSubmit}>
                <div className="col-12">
                    <div className="grid p-fluid">
                        <CustomInputField className="col-12 md:col-12" type="dropdown" iden="active" label="Status*" formik={formik} optionLabel="label" optionValue="value" options={statusValue} placeHolder="Please Select" />
                    </div>
                    <div className="flex flex-row justify-content-end">
                        <div>
                            <Button
                                label="Cancel"
                                onClick={() => {
                                    onHide();
                                }}
                                className="p-button-outlined mr-2"
                            />
                            {permissions?.edit && <Button label="Update" type="submit" className="p-button" />}{" "}
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}
